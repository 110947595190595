import { Point, Points } from "@react-three/drei";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import React, { useRef } from "react";
import { AdditiveBlending, SRGBColorSpace, TextureLoader } from "three";

const particleColors = ["#fff", "#8afffb", "#43b5a0", "#491d88", "#5e5390"];

const Particles = ({ size = 800 }) => {
  const { width, height } = useThree((state) => state.viewport);
  const pointsRef = useRef();

  const texture = useLoader(TextureLoader, "/assets/img/circle.png");
  texture.colorSpace = SRGBColorSpace;

  useFrame(({ clock }) => {
    const randomSpeeds = [0.0001, 0.0002, 0.0003, 0.00009];
    const rotationOptions = ["x", "y"];
    const pointsGroup = pointsRef.current;

    pointsGroup.rotation[
      rotationOptions[Math.floor(Math.random() * rotationOptions.length)]
    ] += randomSpeeds[Math.floor(Math.random() * randomSpeeds.length)];
  });

  return (
    <Points ref={pointsRef} limit={size} renderOrder={-1} position={[0, 0, 0]}>
      <pointsMaterial
        size={0.1}
        vertexColors
        map={texture}
        alphaTest={0.5}
        transparent={false}
        opacity={0.8}
        depthTest={false}
        blending={AdditiveBlending}
      />
      {Array.from({ length: size }).map((_, i) => (
        <Point
          key={i}
          position={[
            (Math.random() - 0.5) * width * 2, // Adjusted to ensure the points stay within the width of the viewport
            (Math.random() - 0.5) * height * 3.5, // Adjusted to ensure the points stay within the height of the viewport
            (Math.random() - 0.8) * 10,
          ]}
          color={
            particleColors[Math.floor(Math.random() * particleColors.length)]
          }
        />
      ))}
    </Points>
  );
};

export default Particles;
