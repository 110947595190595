import { Point, Points } from "@react-three/drei";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import React, { useRef } from "react";
import { AdditiveBlending, SRGBColorSpace, TextureLoader } from "three";

const particleColors = [
  "#fff",
  "#8afffb",
  "#43b5a0",
  "#491d88",
  "#5e5390",
  "#c4d201",
];

const RandomSizeParticlesSection2 = ({ size }) => {
  const { width, height } = useThree((state) => state.viewport);
  const pointsRef = useRef();
  const texture = useLoader(TextureLoader, "/assets/img/circle.png");
  texture.colorSpace = SRGBColorSpace;
  const randomSizeOfPoints = [
    { particlesCount: 2200, pointSize: 0.0248 },
    { particlesCount: 1800, pointSize: 0.0148 },
    { particlesCount: 80, pointSize: 0.0448 },
  ];
  const randomSize = [3.8, 3.9, 4, 4.1, 4.2];
  useFrame(({ clock }) => {
    const randomSpeeds = [
      0.00008, 0.00007, 0.00009, -0.00001, -0.0002, -0.0003,
    ];
    const rotateY = [-0.0005, 0.0005];
    // Access the points group
    const rotationOptions = ["y"];
    const pointsGroup = pointsRef.current;

    pointsGroup.children.forEach((point, i) => {
      if (point.position.y > -2.5) {
        point.position.y +=
          rotateY[Math.floor(Math.random() * (rotateY.length - 1))];
      }
    });
  });

  return (
    <>
      {randomSizeOfPoints &&
        randomSizeOfPoints.map((point1, index) => (
          <Points
            ref={pointsRef}
            limit={2200}
            position={[0, -16, -1]}
            renderOrder={-1}
            key={index}
          >
            <pointsMaterial
              size={point1.pointSize} // Adjust the size of the points
              vertexColors
              map={texture}
              alphaTest={0.5} // Adjust the alpha test value
              transparent={true}
              opacity={0.8} // Adjust the opacity of the points
              depthTest={true} // Disable depth testing for transparency
              blending={AdditiveBlending} // Use additive blending for a brighter appearance
            />
            {/*    0.5 * height + Math.random() ** 0.25 * height * -3, -3 is a count of the frames , ** 0.25 will do somehting like, points from top to bottom */}
            {Array.from({ length: size }).map((_, i) => (
              <Point
                key={i}
                // size={randomSize[Math.floor(Math.random() * randomSize.length)]}
                position={[
                  (0.5 - Math.random()) * width * 2,
                  0.7 * height + Math.random() ** 0.5 * 4 * -1.9,
                  // 3.9,
                  randomSize[Math.floor(Math.random() * randomSize.length)],
                ]}
                // size={Math.random() * 0.5 + 0.1}
                color={
                  particleColors[
                    Math.floor(Math.random() * (particleColors.length - 1))
                  ]
                }
              />
            ))}
          </Points>
        ))}
    </>
  );
};

export default RandomSizeParticlesSection2;
