import { useLoader, useFrame } from "@react-three/fiber";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { LoadingContext } from "../../context/loading.context";
import { useScroll } from "../ScrollControls";
// import { useScroll } from "@react-three/drei";

const BoxWingModel = ({
  modelPath,
  scale,
  position,
  animate,
  direction,
  isPacketInDestination,
}) => {
  const [gltfModel, setGltfModel] = useState(null);
  const [isBoxClosed, setIsBoxClosed] = useState(false);
  const modelRef = useRef();
  const zPositionRef = useRef(position[2]);
  const yScaleRef = useRef(scale[1]);
  const { setLoading } = useContext(LoadingContext);
  const { scroll } = useScroll();

  const gltf = useLoader(GLTFLoader, modelPath);
  gltf.scene.scale.set(...scale);
  gltf.scene.renderOrder = 1;
  const loader = new GLTFLoader();

  useEffect(() => {
    if (gltf.scene) {
      setLoading("boxWing", true);
    }
  }, [gltf, setLoading]);

  useEffect(() => {
    loader.load(modelPath, (gltf) => {
      gltf.scene.scale.set(...scale);
      setGltfModel(gltf);
    });
  }, []);

  useFrame(() => {
    if (modelRef.current && position) {
      const currentScroll = scroll.current * 100;

      if (
        (modelRef.current.rotation.x <= 0.66 && isPacketInDestination) ||
        (currentScroll > 30 && modelRef.current.rotation.x <= 0.66)
        // &&
        // (direction === "down" || !isBoxClosed)
      ) {
        modelRef.current.rotation.x += 0.006;
        modelRef.current.position.y -= 0.0001;
        modelRef.current.position.z -= 0.0017;
        modelRef.current.scale.y -= 0.003;
        if (!isBoxClosed) setIsBoxClosed(true);
      }

      if (
        modelRef.current.rotation.x >= 0 &&
        (direction === "top" || isBoxClosed) &&
        !isPacketInDestination
      ) {
        modelRef.current.rotation.x -= 0.006;
        modelRef.current.position.y += 0.0001;
        modelRef.current.position.z += 0.0017;
        if (isBoxClosed) setIsBoxClosed(false);
        modelRef.current.scale.y += 0.003;
      }

      if (currentScroll < 18.9) {
        modelRef.current.rotation.x = 0;
        modelRef.current.position.y = position[1];
        modelRef.current.position.z = position[2];
        modelRef.current.scale.y = scale[1];
      }

      if (
        currentScroll > 20.6 &&
        currentScroll < 40 &&
        zPositionRef.current !== position[2] &&
        yScaleRef.current !== scale[1]
      ) {
        modelRef.current.rotation.x = 0.66;
        modelRef.current.position.y = position[1];
        modelRef.current.position.z = zPositionRef.current;
        modelRef.current.scale.y = yScaleRef.current;
      }
    }
  });
  return (
    <>
      {gltfModel && (
        <primitive
          ref={modelRef}
          object={gltfModel.scene}
          position={position}
        />
      )}
    </>
  );
};

export default BoxWingModel;
