import { useEffect, useRef, useState } from "react";
import MSDFShader from "../utils/textGradient";
import * as THREE from "three";
import { fragment } from "../shaders/fragment";
import { vertex } from "../shaders/vertex";
import { FontLoader } from "three/addons/loaders/FontLoader.js";
import { useFrame, useThree } from "@react-three/fiber";
import TextGeometryComponent from "../utils/textGeometry";
import { TextGeometry } from "three/addons/geometries/TextGeometry.js";
// import { useScroll } from "@react-three/drei";
import { useScroll } from "./ScrollControls";

const FixedTextComponent = ({
  text,
  align,
  particlesCount = 80,
  scale,
  position,
  sectionName,
  font2,
  isInDestination,
}) => {
  const [generatedTextMesh, setGeneratedTextMesh] = useState(null);
  const [generatedPoints, setGeneratedPoints] = useState(null);
  const [generatedBodyTextMesh, setGeneratedBodyTextMesh] = useState(null);
  const [generatedSubTextMesh, setGeneratedSubTextMesh] = useState(null);
  const [textOpacity, setTextOpacity] = useState(0);
  const subTextMaterial = useRef(null);
  const bodyTextMaterial = useRef(null);
  const textOpacityInterval = useRef(null);
  const isTextRemoveInProgress = useRef(null);
  const isTextAddInProgress = useRef(null);
  const groupRef = useRef(null);
  const { width } = useThree((state) => state.viewport);
  const { scene } = useThree();
  const { scroll } = useScroll();

  // for loading fontTexture
  useEffect(() => {
    if (width < 7.7) {
      const loadFontTexture = async () => {
        try {
          const texture = await new THREE.TextureLoader().loadAsync(
            "/font/manifold.png"
          );

          addText(texture);
        } catch (error) {
          console.error("Error loading font texture:", error);
        }
      };
      loadFontTexture();
    }
  }, [text, scale, position, width]);

  useFrame(() => {
    if (
      subTextMaterial.current &&
      bodyTextMaterial.current &&
      generatedTextMesh
    ) {
      if (textOpacity < 1) {
        subTextMaterial.current.transparent = true;
        subTextMaterial.current.depthWrite = false;
        bodyTextMaterial.current.transparent = true;
        bodyTextMaterial.current.depthWrite = false;
      } else {
        subTextMaterial.current.transparent = false;
        subTextMaterial.current.depthWrite = true;
        bodyTextMaterial.current.transparent = false;
        bodyTextMaterial.current.depthWrite = true;
      }
      subTextMaterial.current.opacity = textOpacity;
      subTextMaterial.current.needsUpdate = true;
      bodyTextMaterial.current.opacity = textOpacity;
      bodyTextMaterial.current.needsUpdate = true;
      generatedTextMesh.material.uniforms.opacity.value = textOpacity;
    }
  });

  // mouse events
  const setupMouseEvents = (materialText, material) => {
    const handleMouseMove = (event) => {
      const mouse = {
        x: event.clientX / window.innerWidth,
        y: event.clientY / window.innerHeight,
      };
      materialText.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
      material.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
    };
    const handleTouchMove = (event) => {
      const mouse = {
        x: event.touches[0].clientX / window.innerWidth,
        y: event.touches[0].clientY / window.innerHeight,
      };
      materialText.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
      material.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
    };
    const canvas = document.querySelector("canvas");
    const handleWindowResize = (event) => {
      materialText.uniforms.viewport.value = new THREE.Vector2(
        canvas.width,
        canvas.height
      );
      material.uniforms.viewport.value = new THREE.Vector2(
        canvas.width,
        canvas.height
      );
    };
    const handleTouchEnd = (event) => {
      materialText.uniforms.uMouse.value = new THREE.Vector2(-0.9, -0.9);
      material.uniforms.uMouse.value = new THREE.Vector2(-0.9, -0.9);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);
  };

  const increaseTextOpacity = () => {
    clearInterval(textOpacityInterval.current);
    textOpacityInterval.current = null;
    isTextRemoveInProgress.current = false;
    const fadeIn = () => {
      setTextOpacity((prevOpacity) => {
        const newValue = Math.min(prevOpacity + 0.05, 1);
        if (newValue > 0.98) {
          clearInterval(textOpacityInterval.current);
          textOpacityInterval.current = null;
          isTextRemoveInProgress.current = false;
        }
        return newValue;
      });
    };

    textOpacityInterval.current = setInterval(() => {
      fadeIn();
    }, 100); // Change the interval as per your requirement
  };

  const decreaseTextOpacity = async (textDetails) => {
    if (textOpacity != 0) {
      clearInterval(textOpacityInterval.current);
      textOpacityInterval.current = null;
      const fadeOut = async () => {
        setTextOpacity((prevOpacity) => {
          const newValue = Math.max(prevOpacity - 0.15, 0);
          if (newValue == 0) {
            isTextRemoveInProgress.current = false;
            clearInterval(textOpacityInterval.current);
            textOpacityInterval.current = null;
            if (generatedPoints) {
              const object = scene.getObjectByProperty(
                "uuid",
                generatedPoints.uuid
              );
              if (object) {
                // object.geometry.dispose();
                // object.material.dispose();
                groupRef.current.remove(object);
                // setGeneratedPoints(null);
              }
            }
            //   removeSectionText(textDetails);
          }
          return newValue;
        });
      };
      textOpacityInterval.current = setInterval(() => {
        fadeOut();
      }, 50); // Change the interval as per your requirement
    }
  };

  // for text movement
  useFrame(({ clock }) => {
    if (generatedTextMesh) {
      generatedTextMesh.material.uniforms.time.value = clock.getElapsedTime();
    }
    if (generatedPoints) {
      generatedPoints.material.uniforms.time.value = clock.getElapsedTime();
    }
  });

  useFrame(() => {
    const currentScroll = scroll.current * 100;

    // add section 6 text
    if (
      currentScroll > sectionName.current.currentMin &&
      currentScroll < sectionName.current.currentMax &&
      width < 7.7
    ) {
      if (
        (!isTextAddInProgress.current &&
          sectionName.current.name != "section7") ||
        (sectionName.current.name == "section7" &&
          isInDestination.current &&
          !isTextAddInProgress.current)
      ) {
        isTextAddInProgress.current = true;
        increaseTextOpacity();
        if (groupRef.current.children.length < 4)
          groupRef.current.add(generatedPoints);
      }
    } else {
      if (!isTextRemoveInProgress.current && textOpacity != 0) {
        isTextRemoveInProgress.current = true;
        isTextAddInProgress.current = false;
        decreaseTextOpacity();
        // setGeneratedPoints(null);
      }
    }
  });
  // addText
  const addText = (fontTexture) => {
    const loader = new FontLoader();
    loader.load("/font/manifold.json", function (font) {
      const geometry = TextGeometryComponent({
        text: sectionName.current.text,
        font: font,
        align: align,
        flipY: fontTexture.flipY,
        scene,
      });

      const material = new THREE.RawShaderMaterial(
        MSDFShader({
          map: fontTexture,
          transparent: true,
          color: 0xffffff,
          side: THREE.DoubleSide,
          glslVersion: THREE.GLSL3,
          depthTest: true,
          depthWrite: true,
          opacity: 0,
        })
      );

      let layout = geometry.geometry.layout;
      let textMesh = new THREE.Mesh(geometry.bufferGeometry, material);

      textMesh.scale.set(...sectionName.current.textScale);
      textMesh.position.set(...sectionName.current.position);

      sectionName.current.uuid = textMesh.uuid;

      setGeneratedTextMesh(textMesh);

      sectionName.current.uuid = textMesh.uuid;
      const textGeometry = new TextGeometry(sectionName.current.subText, {
        font: font2,
        size: sectionName.current.subTextSize,
        height: 4,
      });

      const textMaterial = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        transparent: true,
        opacity: 0,
      });

      const textMesh1 = new THREE.Mesh(textGeometry, textMaterial);
      textMesh1.scale.set(0.006, 0.006, 0);
      textMesh1.position.set(...sectionName.current.subTextPosition);
      subTextMaterial.current = textMaterial;
      sectionName.current.subTextuuid = textMesh1.uuid;
      const textGeometry2 = new TextGeometry(sectionName.current.bodyText, {
        font: font2,
        size: sectionName.current.bodyTextSize,
        height: 4,
      });
      const textMaterial2 = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        transparent: true,
        opacity: 0,
      });
      const textMesh2 = new THREE.Mesh(textGeometry2, textMaterial2);
      textMesh2.scale.set(0.006, 0.006, 0);
      textMesh2.position.set(...sectionName.current.bodyTextPosition);

      // increaseTextOpacity();
      bodyTextMaterial.current = textMaterial2;
      sectionName.current.bodyTextuuid = textMesh2.uuid;

      setGeneratedTextMesh(textMesh);
      setGeneratedSubTextMesh(textMesh1);
      setGeneratedBodyTextMesh(textMesh2);
      addObjects(null, textMesh);
      setupMouseEvents(textMesh.material, material);
    });
  };

  // addObjects
  const addObjects = (layout, textMesh) => {
    const textBoundingBox = new THREE.Box3().setFromObject(textMesh);
    let number = particlesCount;
    let geo = new THREE.BufferGeometry();
    let pos = [];

    for (let i = 0; i < number; i++) {
      // Generate random positions within the bounding box of the text mesh
      let x = THREE.MathUtils.randFloat(
        textBoundingBox.min.x,
        textBoundingBox.max.x
      );
      let y = THREE.MathUtils.randFloat(
        textBoundingBox.min.y,
        textBoundingBox.max.y
      );
      let z = THREE.MathUtils.randFloat(
        textBoundingBox.min.z,
        textBoundingBox.max.z
      );

      pos.push(x, y, z);
    }

    pos = new Float32Array(pos);
    geo.setAttribute("position", new THREE.BufferAttribute(pos, 3));

    const material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable",
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        viewport: {
          value: new THREE.Vector2(window.innerWidth, window.innerHeight),
        },
        pointSize: { value: sectionName.current.pointSize },
        uMouse: { value: new THREE.Vector2(0, 0) },
        resolution: { value: new THREE.Vector4() },
      },
      depthTest: true,
      depthWrite: true,
      transparent: true,
      vertexShader: vertex,
      fragmentShader: fragment,
    });

    const points = new THREE.Points(geo, material);
    setGeneratedPoints(points);
    addTimer(points);
    // groupRef.current.add(points);
    setupMouseEvents(textMesh.material, material);
  };

  // movement of particles
  const addTimer = (mesh) => {
    const boundingBox = new THREE.Box3().setFromObject(mesh);
    const areaBounds = {
      minX: boundingBox.min.x,
      maxX: boundingBox.max.x,
      minY: boundingBox.min.y,
      maxY: boundingBox.max.y,
      minZ: boundingBox.min.z,
      maxZ: boundingBox.max.z,
    };
    const maxVelocity = 0.05; // Maximum speed for particles
    const particlesCount = mesh.geometry.attributes.position.count;
    const velocities = new Array(particlesCount * 3)
      .fill(0)
      .map(() => (Math.random() - 0.5) * maxVelocity);

    setInterval(() => {
      const positions = mesh.geometry.attributes.position.array;

      for (let i = 0; i < positions.length; i++) {
        positions[i] += velocities[i];

        // Check and adjust position to stay within bounds
        if (i % 3 === 0) {
          // X-axis
          if (
            positions[i] < areaBounds.minX ||
            positions[i] > areaBounds.maxX
          ) {
            velocities[i] = -velocities[i]; // Reverse direction upon reaching boundary
          }
          positions[i] = Math.max(
            areaBounds.minX,
            Math.min(areaBounds.maxX, positions[i])
          );
        } else if (i % 3 === 1) {
          // Y-axis
          if (
            positions[i] < areaBounds.minY ||
            positions[i] > areaBounds.maxY
          ) {
            velocities[i] = -velocities[i]; // Reverse direction upon reaching boundary
          }
          positions[i] = Math.max(
            areaBounds.minY,
            Math.min(areaBounds.maxY, positions[i])
          );
        } else {
          // Z-axis
          if (
            positions[i] < areaBounds.minZ ||
            positions[i] > areaBounds.maxZ
          ) {
            velocities[i] = -velocities[i]; // Reverse direction upon reaching boundary
          }
          positions[i] = Math.max(
            areaBounds.minZ,
            Math.min(areaBounds.maxZ, positions[i])
          );
        }
      }
      // Update the buffer geometry to reflect changes
      mesh.geometry.attributes.position.needsUpdate = true;
    }, 100);
  };
  return (
    <group ref={groupRef}>
      {generatedTextMesh && <primitive object={generatedTextMesh} />}
      {generatedSubTextMesh && <primitive object={generatedSubTextMesh} />}
      {generatedBodyTextMesh && <primitive object={generatedBodyTextMesh} />}
    </group>
  );
};
export default FixedTextComponent;
