import React, { createContext, useEffect, useState } from "react";

const defaultValues = {
  loadingStates: {
    section1: false,
    section2: false,
    section2Seed: false,
    section3Maria: false,
    section3Images: false,
    section4: false,
    box: false,
    boxWing: false,
    package: false,
    section5font: false,
    font: false,
    section5json: false,
    flowers: false,
    section1Reached: false,
  },
  setLoading: () => {},
};
export const LoadingContext = createContext(defaultValues);

export const LoadingContextProvider = ({ children }) => {
  const [loadingStates, setLoadingStates] = useState(
    defaultValues.loadingStates
  );

  const [isAllLoaded, setIsAllLoaded] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [currentProgress, setCurrentProgress] = useState(0);

  const setLoading = (sectionKey, status) => {
    if (loadingStates[sectionKey] !== status) {
      setLoadingStates((prev) => ({
        ...prev,
        [sectionKey]: status,
      }));
    }
  };

  useEffect(() => {
    const totalSections = Object.keys(loadingStates).length;
    const completedSections = Object.values(loadingStates).filter(
      (value) => value
    ).length;
    const percentage = (completedSections / totalSections) * 100;
    setLoadingPercentage(Math.round(percentage));
  }, [loadingStates]);

  useEffect(() => {
    if (currentProgress < loadingPercentage) {
      const progressIncrement = Math.ceil(loadingPercentage / 30); // Increase progress by 1/30 of percentage per millisecond to complete within 3 seconds
      const interval = setInterval(() => {
        setCurrentProgress((prevProgress) =>
          Math.min(prevProgress + progressIncrement, loadingPercentage)
        );
      }, 1000 / 30); // Update progress every 30th of a second
      return () => clearInterval(interval);
    } else if (loadingPercentage === 100 && !isAllLoaded) {
      setTimeout(() => {
        setIsAllLoaded(true);
      }, 1000);
    }
  }, [loadingPercentage, currentProgress, isAllLoaded]);

  const value = {
    loadingStates,
    setLoadingStates,
    isAllLoaded,
    setIsAllLoaded,
    setLoading,
    loadingPercentage,
    currentProgress,
  };

  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
};
