import React, { useEffect, useRef, useState } from "react";
import Carousel3D from "./carousel.component";
import BoxModel from "./boxModel.component";
import PacketCarousel3D from "./packetCarousel.component";
import BoxWingModel from "./boxWingModel.component";
import { useThree } from "@react-three/fiber";
import FixedTextComponent from "../fixedText.component";
import { FontLoader } from "three/addons/loaders/FontLoader.js";
import PngLoader from "../pngLoader.component";
import { useScroll } from "../ScrollControls";

const Section5 = () => {
  const [direction, setDirection] = useState();
  const [isPacketInDestination, setIsPacketInDestination] = useState(false);
  const [isPacketInitialPosition, setIsPacketInitialPosition] = useState(false);
  const isInDestination = useRef(false);
  const [font, setFont] = useState(null);
  const [font2, setFont2] = useState(null);
  const [buttonPosition, setButtonPosition] = useState();
  const [buttonScale, setButtonScale] = useState();

  const { scroll } = useScroll();

  const { width } = useThree((state) => state.viewport);

  // for flowers
  const [flowersPosition, setFlowersPosition] = useState();
  const [circleRadius, setCircleRadius] = useState();
  const [flowerRotation, setFlowerRotation] = useState();
  const [modelScales, setModelScales] = useState();
  const [leftArrowPosition, setLeftArrowPosition] = useState();
  const [arrowScale, setArrowScale] = useState();
  const [rightArrowPosition, setRightArrowPosition] = useState();

  // for packets
  const [packetRotation, setPacketRotation] = useState();
  const [packetPosition, setPacketPosition] = useState();
  const [packetScale, setPacketScale] = useState();
  const [packetYRotation, setPacketYRotation] = useState();
  const [packetrightArrowPosition, setPacketRightArrowPosition] = useState();
  const [packetleftArrowPosition, setPacketLeftArrowPosition] = useState();

  // for box
  const [boxScale, setBoxScale] = useState();
  const [boxPosition, setBoxPosition] = useState();
  const [detailImage, setDetailImage] = useState();
  const [detailImagePosition, setDetailImagePosition] = useState();
  const [detailImageScale, setDetailImageScale] = useState();

  useEffect(() => {
    if (width < 5.67) {
      // section 2
      section2TextDetails.current.position = [-width * 0.21, -15.4, 2.6];
      section2TextDetails.current.subTextPosition = [
        -width * 0.21,
        -15.55,
        2.6,
      ];
      section2TextDetails.current.bodyTextPosition = [
        -width * 0.21,
        -15.8,
        2.6,
      ];
      section2TextDetails.current.textScale = [0.0028, -0.0028, 0.005];
      section2TextDetails.current.subTextSize = 9.9;
      section2TextDetails.current.bodyTextSize = 8;
      section2TextDetails.current.subText =
        "Specialized in distribution of high \nquality CBD products.";
      section2TextDetails.current.bodyText =
        "Since 2021 we have been trading flowers \ncreating value for partners and businesses \nby bridging the European Cannabis  \nIndustry. We believe in strong relationships \nto develop a fair ecosystem based \non trust and collaboration.";

      setFlowersPosition([0, -2.29]);
      setCircleRadius(0.18);
      setModelScales([
        [0.025, 0.03, 0.045],
        [0.02, 0.03, 0.045],
        [0.03, 0.035, 0.035],
      ]);
      setFlowerRotation(0.5);
      setPacketPosition([0, -4.32]);
      setPacketScale([0.035, 0.037, 0.01]);
      setPacketRotation(-0.02);
      setPacketYRotation(-0.01);
      setBoxScale([width * 0.11, width * 0.16, width * 0.044]);
      setBoxPosition([0, -5.92, 4.28]);
      setDetailImage("assets/img/Box-Label-Image-Mobile.png");
      setDetailImagePosition([-0.1, 0.25, 0.35]);
      setDetailImageScale([1.4, 0.66, 0.7]);
      setLeftArrowPosition([-0.24, -1.76, 2]);
      setRightArrowPosition([0.24, -1.76, 2]);
      setArrowScale([0.23, 0.23, 1]);
      setPacketLeftArrowPosition([-0.24, -3.42, 2]);
      setPacketRightArrowPosition([0.24, -3.42, 2]);
      setButtonPosition([0, -2.62]);
      setButtonScale([0.03, 0.2]);

      // section 6
      section6TextDetails.current.position = [-width * 0.12, -1.3, 3.6];
      section6TextDetails.current.subTextPosition = [-width * 0.12, -1.4, 3.6];
      section6TextDetails.current.bodyTextPosition = [
        -width * 0.12,
        -1.48,
        3.6,
      ];
      section6TextDetails.current.textScale = [0.002, -0.002, 0.005];
      section6TextDetails.current.subTextSize = 5.7;
      section6TextDetails.current.bodyTextSize = 4.2;
      section6TextDetails.current.text = "DRIVEN BY \nPASSION";
      section6TextDetails.current.bodyText =
        "We curate the finest selections of CBD products, \neach product is meticulously produced and \nselected to deliver the ultimate experience in \nnatural wellness. We prioritize quality at every step \nof the process, from seed to harvest, our hemp flowers \nare organically grown with different setups and features. \nFrom classic favorites to rare cultivars, our collection \noffers something for everyone. Rest assured knowing \nthat all of our CBD hemp flowers undergo rigorous \ntesting by third-party laboratories. Transparency and \nintegrity are at the core of everything we do.";
      // section 7
      section7TextDetails.current.position = [-width * 0.12, -3.4, 3.6];
      section7TextDetails.current.subTextPosition = [-width * 0.12, -3.5, 3.6];
      section7TextDetails.current.bodyTextPosition = [
        -width * 0.12,
        -3.62,
        3.6,
      ];
      section7TextDetails.current.textScale = [0.0017, -0.0017, 0.005];
      section7TextDetails.current.subTextSize = 5.1;
      section7TextDetails.current.bodyTextSize = 4.1;
      section7TextDetails.current.bodyText =
        "Since 2021 we have introduced quality control procedures,\nfilling and packing services and THC reduction treatments \nfor all products we want to distribute. Dalai is consistently \nbecoming the standard for high quality products and \nassurance on procedures and services.";
    } else if (width < 7.7 && width >= 5.67) {
      // section 2
      section2TextDetails.current.position = [-width * 0.21, -6.35, 2.6];
      section2TextDetails.current.subTextPosition = [-width * 0.21, -6.5, 2.6];
      section2TextDetails.current.bodyTextPosition = [
        -width * 0.21,
        -6.74,
        2.6,
      ];
      section2TextDetails.current.textScale = [0.003, -0.003, 0.01];
      section2TextDetails.current.subTextSize = 10.5;
      section2TextDetails.current.bodyTextSize = 7.5;
      section2TextDetails.current.subText =
        "Specialized in distribution of high \nquality CBD products.";
      section2TextDetails.current.bodyText =
        "Since 2021 we have been trading flowers \ncreating value for partners and businesses \nby bridging the European Cannabis  \nIndustry. We believe in strong relationships \nto develop a fair ecosystem based \non trust and collaboration.";

      setFlowersPosition([0, -2.25]);
      setCircleRadius(0.25);
      setFlowerRotation(0.5);
      setModelScales([
        [0.035, 0.04, 0.05],
        [0.03, 0.04, 0.05],
        [0.04, 0.045, 0.04],
      ]);
      setPacketPosition([0, -4.3]);
      setPacketScale([0.04, 0.042, 0.01]);
      setPacketRotation(-0.01);
      setPacketYRotation(0.05);
      setBoxScale([width * 0.084, width * 0.1, width * 0.05]);
      setBoxPosition([0.04, -5.9, 4.35]);
      setDetailImage("assets/img/Box-Label-Image-Desktop.png");
      setDetailImagePosition([-0.14, 0.25, 0.35]);
      setDetailImageScale([1.2, 0.8, 0.7]);
      setLeftArrowPosition([-0.4, -1.68, 2]);
      setRightArrowPosition([0.4, -1.68, 2]);
      setArrowScale([0.25, 0.25, 1]);

      // section 6
      section6TextDetails.current.position = [-width * 0.13, -1.3, 3.6];
      section6TextDetails.current.subTextPosition = [-width * 0.13, -1.4, 3.6];
      section6TextDetails.current.bodyTextPosition = [
        -width * 0.13,
        -1.48,
        3.6,
      ];
      section6TextDetails.current.textScale = [0.0025, -0.0025, 0.01];
      section6TextDetails.current.subTextSize = 5.8;
      section6TextDetails.current.bodyTextSize = 4.5;

      // section 7
      section7TextDetails.current.position = [-width * 0.13, -3.4, 3.6];
      section7TextDetails.current.subTextPosition = [-width * 0.13, -3.5, 3.6];
      section7TextDetails.current.bodyTextPosition = [
        -width * 0.13,
        -3.62,
        3.6,
      ];
      section7TextDetails.current.textScale = [0.0025, -0.0025, 0.01];
      section7TextDetails.current.subTextSize = 5.8;
      section7TextDetails.current.bodyTextSize = 4.5;
      setPacketLeftArrowPosition([-0.4, -3.38, 2]);
      setPacketRightArrowPosition([0.38, -3.38, 2]);

      setButtonPosition([0, -2.66]);
      setButtonScale([0.04, 0.3]);
    } else if (width >= 7.7 && width < 11.34) {
      setFlowersPosition([0.4, -2.12]);
      setCircleRadius(0.35);
      setModelScales([
        [0.042, 0.046, 0.06],
        [0.038, 0.046, 0.055],
        [0.048, 0.05, 0.05],
      ]);
      setFlowerRotation(0.15);
      setPacketPosition([0.33, -4.16]);
      setPacketScale([0.05, 0.05, 0.012]);
      setPacketRotation(-0.33);
      setPacketYRotation(0.3);
      setBoxScale([width * 0.069, width * 0.076, width * 0.022]);
      setBoxPosition([0.1, -5.87, 4.436]);
      setDetailImage("assets/img/Box-Label-Image-Desktop.png");
      setDetailImagePosition([-0.14, 0.25, 0.35]);
      setDetailImageScale([1.2, 0.8, 0.7]);
      setLeftArrowPosition([-0.05, -1.56, 2]);
      setRightArrowPosition([0.78, -1.56, 2]);
      setArrowScale([0.25, 0.25, 1]);
      setPacketLeftArrowPosition([-0.1, -3.2, 2]);
      setPacketRightArrowPosition([0.84, -3.2, 2]);
      setButtonPosition([0.58, -2.5]);
      setButtonScale([0.04, 0.3]);
    } else if (width >= 11.34 && width <= 14.5) {
      setFlowersPosition([0.46, -2.12]);
      setCircleRadius(0.32);
      setFlowerRotation(0.07);
      setPacketPosition([0.42, -4.2]);
      setPacketRotation(-0.41);
      setPacketYRotation(0.44);
      setBoxScale([0.7, 0.7, 0.4]);
      setBoxPosition([0.1, -5.83, 4.4]);
      setModelScales([
        [0.04, 0.044, 0.06],
        [0.036, 0.044, 0.055],
        [0.046, 0.048, 0.05],
      ]);
      setPacketScale([0.045, 0.045, 0.02]);
      setDetailImage("assets/img/Box-Label-Image-Desktop.png");
      setDetailImagePosition([-0.14, 0.25, 0.35]);
      setDetailImageScale([1.2, 0.8, 0.7]);
      setLeftArrowPosition([-0.05, -1.5, 2]);
      setRightArrowPosition([0.88, -1.5, 2]);
      setPacketLeftArrowPosition([-0.03, -3.13, 2]);
      setPacketRightArrowPosition([0.8, -3.13, 2]);
      setArrowScale([0.3, 0.3, 1]);
      setButtonPosition([0.67, -2.45]);
      setButtonScale([0.04, 0.3]);
    } else {
      setFlowersPosition([0.5, -2.1]);
      setCircleRadius(0.4);
      setFlowerRotation(0.07);
      setPacketPosition([0.45, -4.2]);
      setPacketRotation(-0.435);
      setPacketYRotation(0.44);
      setBoxScale([0.8, 0.8, 0.42]);
      setBoxPosition([0.1, -5.83, 4.467]);
      setModelScales([
        [0.041, 0.045, 0.06],
        [0.038, 0.046, 0.052],
        [0.048, 0.05, 0.05],
      ]);
      setPacketScale([0.064, 0.065, 0.00001]);
      // setPacketScale([2.6, 3, 0.02]);
      setDetailImage("assets/img/Box-Label-Image-Desktop.png");
      setDetailImagePosition([-0.14, 0.25, 0.35]);
      setDetailImageScale([1.2, 0.8, 0.7]);
      setLeftArrowPosition([-0.05, -1.48, 2]);
      setRightArrowPosition([1.03, -1.48, 2]);
      setPacketLeftArrowPosition([-0.1, -3.15, 2]);
      setPacketRightArrowPosition([1, -3.15, 2]);
      setArrowScale([0.27, 0.27, 1]);
      setButtonPosition([0.75, -2.5]);
      setButtonScale([0.04, 0.3]);
    }
  }, [width]);

  // for loading fontTexture
  useEffect(() => {
    const loadFontTexture = async () => {
      try {
        const loader = new FontLoader();

        loader.load("/font/manifold.json", function (font) {
          setFont(font);
        });

        loader.load(
          "https://threejs.org/examples/fonts/helvetiker_regular.typeface.json",
          function (font) {
            setFont2(font);
          }
        );
      } catch (error) {
        console.error("Error loading font texture:", error);
      }
    };
    loadFontTexture();
  }, []);

  // section 6 details
  const section6TextDetails = useRef({
    isAdded: false,
    text: "DRIVEN BY PASSION",
    align: "center",
    pointSize: 1.3,
    subText: "We only commit to excellence.",
    currentMin: 4,
    name: "section6",
    currentMax: 7.5,
    bodyText:
      "We curate the finest selections of CBD products, each product is \nmeticulously produced and selected to deliver the ultimate experience in \nnatural wellness. We prioritize quality at every step of the process, \nfrom seed to harvest, our hemp flowers are organically grown with \ndifferent setups and features. From classic favorites to rare cultivars, \nour collection offers something for everyone. Rest assured knowing that \nall of our CBD hemp flowers undergo rigorous testing by third-party \nlaboratories. Transparency and integrity are at the core of everything we do.",
  });

  // section 2 details
  const section2TextDetails = useRef({
    isAdded: false,
    pointSize: 2,
    text: "DRIVEN BY GROWTH",
    align: "center",
    subText: "Specialized in distribution of high \nquality CBD products.",
    bodyText:
      "Since 2021 we have been trading flowers creating value \nfor partners and businesses by bridging the European \nCannabis Industry. We believe in strong relationships to \ndevelop a fair ecosystem based on trust and collaboration.",
    currentMin: 58.5,
    name: "section2",
    currentMax: 61.55,
  });

  // section 7 details
  let section7TextDetails = useRef({
    isAdded: false,
    text: "WE ARE YOUR\nTRUSTED SUPPLIER",
    align: "center",
    pointSize: 1.3,
    subText: "Dalai offers complementary services to ease\nyour operations.",
    currentMin: 14.2,
    currentMax: 17.5,
    name: "section7",
    bodyText:
      "Since 2021 we have introduced quality control procedures,\nfilling and packing services and THC reduction treatments for all\nproducts we want to distribute. Dalai is consistently becoming the\nstandard for high quality products and assurance\non procedures and services.",
  });

  return (
    <>
      {packetPosition &&
        packetRotation &&
        packetScale &&
        circleRadius &&
        packetYRotation && (
          <PacketCarousel3D
            direction={direction}
            setDirection={setDirection}
            isPacketInitialPosition={isPacketInitialPosition}
            setIsPacketInDestination={setIsPacketInDestination}
            isPacketInDestination={isPacketInDestination}
            setIsPacketInitialPosition={setIsPacketInitialPosition}
            isInDestination={isInDestination}
            position={[packetPosition[0], packetPosition[1], 4.1]}
            packetRotation={packetRotation}
            packetScale={packetScale}
            radius={circleRadius}
            packetYRotation={packetYRotation}
            arrowScale={arrowScale}
            packetleftArrowPosition={packetleftArrowPosition}
            packetrightArrowPosition={packetrightArrowPosition}
          />
        )}
      {flowerRotation && circleRadius && modelScales && flowersPosition && (
        <Carousel3D
          direction={direction}
          setDirection={setDirection}
          isPacketInitialPosition={isPacketInitialPosition}
          isInDestination={isInDestination}
          position={[flowersPosition[0], flowersPosition[1], 4]}
          radius={circleRadius}
          flowerRotation={flowerRotation}
          modelScales={modelScales}
          leftArrowPosition={leftArrowPosition}
          rightArrowPosition={rightArrowPosition}
          arrowScale={arrowScale}
          buttonPosition={buttonPosition}
          buttonScale={buttonScale}
        />
      )}

      {font && font2 && (
        <>
          <FixedTextComponent
            sectionName={section6TextDetails}
            font={font}
            font2={font2}
          />

          <FixedTextComponent
            sectionName={section2TextDetails}
            font={font}
            font2={font2}
          />

          <FixedTextComponent
            sectionName={section7TextDetails}
            font={font}
            font2={font2}
            isInDestination={isInDestination}
          />
        </>
      )}

      {boxPosition &&
        boxScale &&
        detailImage &&
        detailImagePosition &&
        detailImageScale && (
          <>
            <BoxModel
              modelPath="/assets/3d-Models/Box_big.glb"
              scale={boxScale}
              position={[boxPosition[0], boxPosition[1], boxPosition[2]]}
              rotating={true}
              animate={false}
              direction={direction}
              isPacketInDestination={isPacketInDestination}
              setDirection={setDirection}
              detailImage={detailImage}
              detailImagePosition={detailImagePosition}
              detailImageScale={detailImageScale}
            />
            <BoxWingModel
              modelPath="/assets/3d-Models/Box_wing.glb"
              scale={boxScale}
              position={[boxPosition[0], boxPosition[1], boxPosition[2]]}
              rotating={true}
              animate={false}
              direction={direction}
              isPacketInDestination={isPacketInDestination}
              setDirection={setDirection}
            />
          </>
        )}
    </>
  );
};

export default Section5;
