import { useFrame, useLoader, useThree } from "@react-three/fiber";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { LoadingContext } from "../../context/loading.context";
import { useScroll } from "../ScrollControls";
import { ScrollContext } from "../../context/scroll.context";
import { Mesh } from "three";
import * as THREE from "three";
import { Section8Context } from "../../context/section8.context";

import { setScrollTopToPercentage } from "../../utils/setScrollPercentage";
import PngLoader from "../pngLoader.component";
const BoxModel = ({
  modelPath,
  scale,
  position,
  direction,
  isPacketInDestination,
  detailImage,
  detailImagePosition,
  detailImageScale,
}) => {
  const [gltfModel, setGltfModel] = useState(null);
  const { setLoading } = useContext(LoadingContext);
  const { setEnableScroll, enableScroll } = useContext(ScrollContext);
  const { showContactPage, setShowContactPage } = useContext(Section8Context);
  const prevScrollRef = useRef(null);
  const modelRef = useRef();
  const { scroll, el } = useScroll();
  const { camera } = useThree();

  const gltf = useLoader(GLTFLoader, modelPath);
  gltf.scene.scale.set(...scale);
  gltf.scene.renderOrder = 1;

  useEffect(() => {
    if (gltf && gltf.scene) {
      setLoading("box", true);
    }
  }, [gltf, setLoading]);

  useEffect(() => {
    if (gltf.scene) {
      const imageTexture = new THREE.TextureLoader().load(detailImage);
      const imageMaterial = new THREE.MeshStandardMaterial({
        map: imageTexture,
        transparent: true,
        side: THREE.DoubleSide,
        depthWrite: true,
      });

      const imageGeometry = new THREE.PlaneGeometry(0.5, 0.5, 10, 10);
      const imageMesh = new Mesh(imageGeometry, imageMaterial);

      imageMesh.position.set(...detailImagePosition);
      imageMesh.scale.set(...detailImageScale);
      // Load texture for imageMesh1
      const imageTexture1 = new THREE.TextureLoader().load(
        "assets/img/emailImage.png"
      );
      const imageMaterial1 = new THREE.MeshStandardMaterial({
        map: imageTexture1,
        transparent: true,
        side: THREE.DoubleSide,
        depthWrite: true,
      });
      const imageGeometry1 = new THREE.PlaneGeometry(0.5, 0.5, 10, 10);
      const imageMesh1 = new Mesh(imageGeometry1, imageMaterial1);
      imageMesh1.position.set(-0.023, 0.29, 0);
      imageMesh1.scale.set(0.66, 0.14, 0.7);

      // Load texture for imageMesh2
      const imageTexture2 = new THREE.TextureLoader().load(
        "assets/img/instaimage.png"
      );
      const imageMaterial2 = new THREE.MeshStandardMaterial({
        map: imageTexture2,
        transparent: true,
        side: THREE.DoubleSide,
        depthWrite: true,
      });
      const imageGeometry2 = new THREE.PlaneGeometry(0.5, 0.5, 10, 10);
      const imageMesh2 = new Mesh(imageGeometry2, imageMaterial2);
      imageMesh2.position.set(-0.023, 0.2, 0.3);
      imageMesh2.scale.set(0.66, 0.14, 0.7);

      // Load texture for imageMesh3
      const imageTexture3 = new THREE.TextureLoader().load(
        "assets/img/vatimage.png"
      );
      const imageMaterial3 = new THREE.MeshStandardMaterial({
        map: imageTexture3,
        transparent: true,
        side: THREE.DoubleSide,
        depthWrite: true,
      });
      const imageGeometry3 = new THREE.PlaneGeometry(0.5, 0.5, 10, 10);
      const imageMesh3 = new Mesh(imageGeometry3, imageMaterial3);
      imageMesh3.position.set(-0.01, 0.383, 0);
      imageMesh3.scale.set(0.66, 0.14, 0.7);

      // Create raycaster and mouse vector
      const raycaster = new THREE.Raycaster();
      const mouse = new THREE.Vector2();

      const handleImage1Click = (event) => {
        // Update the mouse position
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        // Cast a ray from the camera through the mouse position
        raycaster.setFromCamera(mouse, camera);

        // Check for intersections with imageMesh1
        const intersects = raycaster.intersectObjects([imageMesh1]);
        if (intersects.length > 0) {
          // Image mesh 1 clicked
          window.location.href = "mailto:info@intodalai.com";
        }
      };

      const handleImage2Click = (event) => {
        // Update the mouse position
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        // Cast a ray from the camera through the mouse position
        raycaster.setFromCamera(mouse, camera);

        // Check for intersections with imageMesh2
        const intersects = raycaster.intersectObjects([imageMesh2]);
        if (intersects.length > 0) {
          // Image mesh 2 clicked
          window.location.href =
            "https://www.instagram.com/intodalai?igsh=ZmVianBhdzB6MHdt";
        }
      };

      const handleImage3Click = (event) => {
        // Update the mouse position
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        // Cast a ray from the camera through the mouse position
        raycaster.setFromCamera(mouse, camera);

        // Check for intersections with imageMesh3
        const intersects = raycaster.intersectObjects([imageMesh3]);
        if (intersects.length > 0) {
          // Image mesh 3 clicked
          window.location.href = "tel:+393441566992";
        }
      };

      // Add event listeners for each mesh
      window.addEventListener("click", handleImage1Click);
      window.addEventListener("click", handleImage2Click);
      window.addEventListener("click", handleImage3Click);

      // Add meshes to the scene
      gltf.scene.add(imageMesh);
      gltf.scene.add(imageMesh1);
      gltf.scene.add(imageMesh2);
      gltf.scene.add(imageMesh3);

      return () => {
        // Cleanup event listeners when component unmounts
        window.removeEventListener("click", handleImage1Click);
        window.removeEventListener("click", handleImage2Click);
        window.removeEventListener("click", handleImage3Click);
      };
    }
  }, [gltf, camera]);

  const handleImageClick = () => {
    // Your click handling logic here
    alert("Image clicked!");
  };

  useFrame(() => {
    if (modelRef.current) {
      const currentScroll = scroll.current * 100;

      if (currentScroll >= 19 && currentScroll <= 20.7) {
        scroll.current = 20.6 / 100;
        if (currentScroll > 20.3 && currentScroll < 20.7) {
          setScrollTopToPercentage(20.6, el);
        }
      }

      if (showContactPage) {
        if (scroll.current <= 0.97 && currentScroll > 20.6) {
          scroll.current += 0.1 / 100;
          setScrollTopToPercentage(99, el);
        }
        if (currentScroll > 0 && currentScroll <= 20.6) {
          scroll.current += 0.1 / 100;
          setScrollTopToPercentage(20.6, el);
        }
        setTimeout(() => {
          setShowContactPage(false);
        }, 1000);
        // scroll.current = 20.6 / 100;
        // setScrollTopToPercentage(20.6, el);
        // setTimeout(() => {
        //   setShowContactPage(false);
        // }, 1000);
      }

      // if (el.scrollTop > 920 && el.scrollTop < 1097) {
      //   el.scrollTop -= 2;
      // }

      // if (currentScroll > 22.4 && currentScroll < 35) {
      //   scroll.current += 0.08 / 100;
      // }

      // if (el.scrollTop >= 1197 && el.scrollTop < 1490) {
      //   scroll.current += 0.6 / 100;
      // }

      // if (currentScroll < 22.4 && currentScroll > 21) {
      //   scroll.current -= 0.04 / 100;
      // }

      // section 7 exit
      if (currentScroll > 20.7 && currentScroll < 26) {
        scroll.current -= 0.04 / 100;
      }

      if (currentScroll > 20.6 && currentScroll < 21) {
        setScrollTopToPercentage(20.6, el);
      }

      prevScrollRef.current = scroll.current;
      if (
        currentScroll >= 20 &&
        !isPacketInDestination &&
        scroll.current < 0.3 &&
        direction != "top" &&
        !showContactPage
      ) {
        scroll.current = 0.206;
        prevScrollRef.current = 20.5;
        setScrollTopToPercentage(20.6, el);
        if (enableScroll && !isPacketInDestination) setEnableScroll(false);
      }
    }
  });

  return (
    <>
      {gltf && (
        <primitive
          ref={modelRef}
          object={gltf.scene}
          position={position}
          onPointerOver={(e) => {
            document.body.style.cursor = "pointer";
            e.stopPropagation();
          }}
          onPointerOut={(e) => {
            document.body.style.cursor = "auto";
            e.stopPropagation();
          }}
        />
      )}
    </>
  );
};

export default BoxModel;
