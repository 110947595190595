import React, { useEffect, useMemo, useState } from "react";
import PngLoader from "../pngLoader.component";
import TextComponent from "../text.component";
import PlantModel from "./BiscottiModel.component";
import Particles from "./particles.component";
import ParticlesSection5 from "../section5/particles.component";
import RandomSizeParticlesSection2 from "../section2/randomSizeParticlesSection2.component";
import { useThree } from "@react-three/fiber";
import RandomSizeParticlesSection4Top from "../section4/particles.component";
import RandomSizeParticlesSection4Down from "../section4/downParticles.component";

const Section1 = () => {
  const { width } = useThree((state) => state.viewport);
  const [dalaiImageScale, setDalaiImageScale] = useState();
  const [plantModelScale, setPlantModelScale] = useState();
  const [textAnimationScale, setTextAnimationScale] = useState();
  const [textAnimationPosition, setTextAnimationPosition] = useState();
  const [plantModelPosition, setPlantModelPosition] = useState();
  const [section2ParticleSize, setSection2ParticleSize] = useState();
  const [dalaiImagePosition, setDalaiImagePosition] = useState();
  const [buttonTextSize, setButtonTextSize] = useState();
  const [buttonPosition, setButtonPosition] = useState();
  const [buttonScale, setButtonScale] = useState();
  const [buttonHover, setButtonHover] = useState(false);

  useEffect(() => {
    if (width < 5.27) {
      setDalaiImagePosition([0, -9.55]);
      setDalaiImageScale(1.1);
      setPlantModelPosition([-width * 0.26, -12.6, 0.5]);
      setPlantModelScale([70, 70, 70]);
      setTextAnimationScale(width * 0.00061);
      setTextAnimationPosition([-width * 0.18, -10, 2.4]);
      setButtonTextSize(0.1);
      setButtonPosition([-0, -10.24]);
      setButtonScale([0.062, 0.5]);
    } else if (width < 7.7 && width >= 5.27) {
      setDalaiImagePosition([0, -9.35]);
      setDalaiImageScale(width * 0.22);
      setPlantModelPosition([-width * 0.2, -12.9, 0.5]);
      setPlantModelScale([80, 80, 90]);
      setTextAnimationScale(width * 0.00058);
      setTextAnimationPosition([-width * 0.17, -10, 2.4]);
      setButtonTextSize(0.12);
      setButtonPosition([-0, -10.3]);
      setButtonScale([0.07, 0.5]);
    } else if (width >= 7.7 && width < 11.34) {
      setDalaiImagePosition([0, -9.3]);
      setDalaiImageScale(width * 0.18);
      setPlantModelPosition([-width * 0.091, -12.9, 0.5]);
      setPlantModelScale([80, 80, 90]);
      setTextAnimationScale(width * 0.00051);
      setTextAnimationPosition([-width * 0.15, -10.2, 2.4]);
      setButtonTextSize(0.16);
      setButtonPosition([0, -10.5]);
      setButtonScale([0.08, 0.5]);
    } else if (width >= 11.34 && width <= 14.5) {
      setDalaiImagePosition([0, -9.4]);
      setDalaiImageScale(width * 0.14);
      setPlantModelPosition([-width * 0.091, -13.8, 0.5]);
      setPlantModelScale([90, 90, 80]);
      setTextAnimationScale(width * 0.00043);
      setTextAnimationPosition([-width * 0.13, -10.2, 2.4]);
      setButtonTextSize(0.16);
      setButtonPosition([0, -10.5]);
      setButtonScale([0.09, 0.6]);
    } else {
      setDalaiImagePosition([-0.15, -9.2]);
      setDalaiImageScale(width * 0.125);
      setPlantModelPosition([-width * 0.096, -14, 0.5]);
      setPlantModelScale([width * 6.48, width * 6.48, 80]);
      setTextAnimationPosition([-width * 0.13, -10.2, 2.4]);
      setTextAnimationScale(width * 0.00043);
      setSection2ParticleSize(120);
      setButtonTextSize(0.16);
      setButtonPosition([-0.1, -10.55]);
      setButtonScale([0.09, 0.6]);
    }
  }, [width]);

  return (
    <>
      {dalaiImageScale && dalaiImagePosition && (
        <>
          <mesh position={[dalaiImagePosition[0], dalaiImagePosition[1], 2.4]}>
            <PngLoader
              renderOrder={-100}
              transparent={true}
              path="assets/img/dalai logo 1.svg"
              scale={[dalaiImageScale, dalaiImageScale, 1]}
            />
          </mesh>
          <mesh position={[buttonPosition[0], buttonPosition[1], 2.4]}>
            {/* <planeGeometry args={buttonScale} />
            <meshBasicMaterial color="white" transparent opacity={0.3} /> */}
          </mesh>
          <mesh
            renderOrder={-10}
            position={[buttonPosition[0], buttonPosition[1], 2.4]}
            onClick={() => {
              window.location.href = "https://catalogue.intodalai.com/";
            }}
          >
            <mesh
              onPointerOver={() => (
                (document.body.style.cursor = "pointer"), setButtonHover(true)
              )}
              onPointerLeave={() => (
                (document.body.style.cursor = "auto"), setButtonHover(false)
              )}
              scale={[buttonScale[0], buttonScale[1], 1.5]}
            >
              <PngLoader
                renderOrder={buttonHover ? -100 : 0}
                transparent={true}
                path="assets/img/Explore 3D Catalogue button - 4X.png"
                scale={[4.1, 0.65, 0.5]}
              />
            </mesh>
          </mesh>
        </>
      )}

      <Particles size={150} />
      <RandomSizeParticlesSection2 size={120} />
      <ParticlesSection5 />
      <RandomSizeParticlesSection4Top />
      <RandomSizeParticlesSection4Down />

      {plantModelPosition && plantModelScale && (
        <>
          <mesh rotation={[0, 0.2, -0.43]} position={plantModelPosition}>
            <PlantModel
              modelPath="/assets/3d-Models/LAVENDER KUSH/LAVENDER_KUSH_7K_HQ.glb"
              scale={plantModelScale}
              rotating={true}
              animate={false}
            />
          </mesh>
        </>
      )}

      <PngLoader
        transparent={true}
        renderOrder={-100}
        path="assets/img/Gradient-06-Color-Magenta-compressed.png"
        position={[0, -1.5, -1]}
        scale={[5, 4.3, 1]}
      />

      {textAnimationPosition && (
        <TextComponent
          text={`BRIDGING CBD INDUSTRY`}
          align={"center"}
          particlesCount={100}
          scale={[textAnimationScale, -textAnimationScale, 0.02]}
          position={textAnimationPosition}
          pointSize={2.0}
        />
      )}
    </>
  );
};

export default Section1;
