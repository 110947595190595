import React, { useState, useContext, useEffect, useRef } from "react";
import MariaModel from "./mariaModel.component";
import { Section3Context } from "../../context/section3.context";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import { useScroll } from "../ScrollControls";
import VideoLoader from "./section3VideoLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const Section3 = () => {
  const { el } = useScroll();
  const {
    timeRange,
    setShowExperianceButton,
    showExperianceButton,
    setShowPlayButtons,
    showPlayButtons,
  } = useContext(Section3Context);

  const { scroll } = useScroll();
  const { width } = useThree((state) => state.viewport);
  const [mariaModelScale, setMariaModelScale] = useState();
  const gltfMOdel = useLoader(
    GLTFLoader,
    "/assets/3d-Models/Single_Maria_APPROVED.gltf"
  );
  const gltfMOdel2 = useRef();
  useEffect(() => {
    if (width < 5.67) {
      setMariaModelScale([width * 0.2, 1]);
    } else if (width < 7.7 && width >= 5.67) {
      setMariaModelScale([width * 0.1, 1]);
    } else if (width >= 7.7 && width < 11.34) {
      setMariaModelScale([width * 0.039, 1.15]);
    } else if (width >= 11.34 && width <= 14.5) {
      setMariaModelScale([width * 0.039, 1.15]);
    } else {
      setMariaModelScale([width * 0.039, 1.15]);
    }
  }, [width]);
  useEffect(() => {
    if (gltfMOdel && gltfMOdel.scene && !gltfMOdel2.current) {
      gltfMOdel2.current = gltfMOdel.scene.clone(true);
    }
  }, [gltfMOdel]);

  useFrame(() => {
    const currentScroll = scroll.current * 100;
    if (
      currentScroll > 79 &&
      currentScroll <= 93 &&
      !showExperianceButton &&
      !showPlayButtons
    ) {
      setShowExperianceButton(true);
    }
    if ((currentScroll < 62 || currentScroll > 95) && showExperianceButton) {
      setShowExperianceButton(false);
      setShowPlayButtons(false);
    }
  });
  return (
    <>
      <VideoLoader
        width={28}
        height={15}
        position={[0, -23.3, -1]}
        scale={[1, 1.2, 1]}
        startTime={timeRange.startTime}
      />
      {mariaModelScale && gltfMOdel && (
        <mesh position={[0, -27.2, 3]} rotation={[0, -1.8, 0]}>
          <MariaModel
            gltf={gltfMOdel.scene}
            scale={[mariaModelScale[0], 1.35, mariaModelScale[1]]}
            animate={false}
            showLight={true}
          />
        </mesh>
      )}
      {mariaModelScale && gltfMOdel2.current && (
        <mesh position={[0, 0.3, 3]} rotation={[0, -1.8, 0]}>
          <MariaModel
            gltf={gltfMOdel2.current}
            scale={[mariaModelScale[0], 1.5, mariaModelScale[1]]}
            animate={false}
            showLight={false}
          />
        </mesh>
      )}
    </>
  );
};

export default Section3;
