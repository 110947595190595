import "./App.css";
import React from "react";
import Routers from "./routes/Routers";
import { LoadingContextProvider } from "./context/loading.context";
import { ScrollContextProvider } from "./context/scroll.context";
import { Section3ContextProvider } from "./context/section3.context";
import { Section8ContextProvider } from "./context/section8.context";

const App = () => {
  return (
    <LoadingContextProvider>
      <ScrollContextProvider>
        <Section3ContextProvider>
          <Section8ContextProvider>
            <Routers />
          </Section8ContextProvider>
        </Section3ContextProvider>
      </ScrollContextProvider>
    </LoadingContextProvider>
  );
};

export default App;
