import { Canvas, useThree } from "@react-three/fiber";
import React, { Suspense, useContext, useEffect, useRef } from "react";
import Section1 from "../components/section1/section1.component";
import Section2 from "../components/section2/section2.component";
import Section3 from "../components/section3/section3.component";
import {
  Scroll,
  ScrollControls,
  useScroll,
} from "../components/ScrollControls";
import Section4 from "../components/section4/section4.component";
import Section5 from "../components/section5/section5.component";
import { Section3Context } from "../context/section3.context";
import MovingTextComponent from "../components/movingText.component";
import LoadingPage from "../components/LoadingPage.component";
import { useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Html, useGLTF } from "@react-three/drei";
import { LoadingContext } from "../context/loading.context";
// import { Scroll, ScrollControls } from "@react-three/drei";

const Home = () => {
  const {
    showPlayButtons,
    setShowPlayButtons,
    handleButtonClick,
    showExperianceButton,
    handleHideButtonClick,
    setActiveMode,
    activeMode,
  } = useContext(Section3Context);
  const [dumping, setdumping] = useState(4);
  const { isAllLoaded, currentProgress } = useContext(LoadingContext);

  useEffect(() => {
    if (navigator && navigator.platform.toLowerCase().includes("mac")) {
      setdumping(2.5);
    }
  }, []);

  return (
    <>
      <LoadingPage />

      {(showPlayButtons || showExperianceButton) && (
        <div className="fixed top-0 left-0 w-full h-[100dvh] z-[12] pointer-events-none">
          {showPlayButtons && (
            <>
              <div className="flex flex-col items-center gap-4 absolute bottom-10 3xl:right-24 right-4 sm:right-9 bounce-animation">
                <button
                  onClick={() => (
                    handleButtonClick(0, 11.48), setActiveMode("outdoor")
                  )}
                  className={`pointer-events-auto w-20 3xl:w-28 3xl:h-28 lg:w-24 h-20 lg:h-24 border-2 border-solid border-white uppercase text-center text-[9px] sm:text-xs lg:text-base leading-[1.2] text-white rounded-full flex items-center justify-center active:scale-95 hover:shadow-[0px_0px_20px_#ffffff00,_0px_0px_20px_#ffffff75] transition-all duration-300 ${
                    activeMode == "outdoor"
                      ? "bg-lime-950 shadow-[0px_0px_20px_#ffffff00,_0px_0px_20px_#ffffff75]"
                      : ""
                  }`}
                >
                  outdoor
                </button>
                <button
                  onClick={() => (
                    handleButtonClick(1, 6.6), setActiveMode("green")
                  )}
                  className={`pointer-events-auto  3xl:w-28 3xl:h-28  w-20 lg:w-24 h-20 lg:h-24 border-2 border-solid border-white uppercase text-center text-[9px] sm:text-xs lg:text-lg leading-[1.2] text-white rounded-full flex items-center justify-center active:scale-95 hover:shadow-[0px_0px_20px_#ffffff00,_0px_0px_20px_#ffffff75] transition-all duration-300 ${
                    activeMode == "green"
                      ? "bg-lime-950 shadow-[0px_0px_20px_#ffffff00,_0px_0px_20px_#ffffff75]"
                      : ""
                  }`}
                >
                  Green House
                </button>

                <button
                  onClick={() => (
                    handleButtonClick(2, 12.7), setActiveMode("indoor")
                  )}
                  className={`pointer-events-auto  3xl:w-28 3xl:h-28  w-20 lg:w-24 h-20 lg:h-24 border-2 border-solid border-white uppercase text-center text-[9px] sm:text-xs lg:text-lg leading-[1.2] text-white rounded-full flex items-center justify-center active:scale-95 hover:shadow-[0px_0px_20px_#ffffff00,_0px_0px_20px_#ffffff75] transition-all duration-300 ${
                    activeMode == "indoor"
                      ? "bg-lime-950 shadow-[0px_0px_20px_#ffffff00,_0px_0px_20px_#ffffff75]"
                      : ""
                  }`}
                >
                  Indoor
                </button>
              </div>
              <button
                onClick={handleHideButtonClick}
                className=" pointer-events-auto absolute top-[58px] sm:top-[66px] 3xl:right-24 right-9 w-8 h-8 sm:w-10 sm:h-10  lg:w-12 lg:h-12 border-2 border-solid border-white uppercase text-center text-lg leading-[1.2] text-white rounded-full flex items-center justify-center active:scale-95 hover:shadow-[0px_0px_20px_#ffffff00,_0px_0px_20px_#ffffff75] transition-all duration-300 fadeIn"
              >
                <span className=" w-5 sm:w-6 lg:w-7 h-0.5 bg-white inline-block absolute -rotate-45" />
                <span className=" w-5 sm:w-6 lg:w-7 h-0.5 bg-white inline-block absolute rotate-45" />
              </button>
            </>
          )}
          {showExperianceButton && !showPlayButtons && isAllLoaded && (
            <button
              onClick={() => setShowPlayButtons(true)}
              className="pointer-events-auto absolute bottom-10 3xl:bottom-20 3xl:right-24 right-4 lg:right-9 3xl:w-40 3xl:h-40 w-20 h-20 sm:w-24 lg:w-32  sm:h-24 lg:h-32 border-2 border-solid border-white uppercase text-center text-[9px] sm:text-xs lg:text-base leading-[1.2] text-white rounded-full flex items-center justify-center active:scale-95 hover:shadow-[0px_0px_20px_#ffffff00,_0px_0px_20px_#ffffff75] bounce-animation "
            >
              Experience
            </button>
          )}
        </div>
      )}

      <Canvas gl={{ alpha: true, antialias: true }} shadows>
        <color attach="background" args={["#11272e"]} />
        <ambientLight intensity={1} />
        <Suspense fallback={null}>
          <ScrollControls
            infinite
            vertical
            pages={4.52}
            damping={dumping}
            distance={1}
          >
            <Scroll>
              {/* <PreloadModels /> */}
              <Section1 page={10} />
              <Section2 page={10} />
              <Section3 />
              <Section4 />
              <Section5 />
            </Scroll>
          </ScrollControls>
        </Suspense>
      </Canvas>
    </>
  );
};

export default Home;
