import { useContext, useEffect, useRef, useState } from "react";
import MSDFShader from "../utils/textGradient";
import * as THREE from "three";
import { fragment } from "../shaders/fragment";
import { vertex } from "../shaders/vertex";
import { FontLoader } from "three/addons/loaders/FontLoader.js";
import { useFrame, useThree } from "@react-three/fiber";
import TextGeometryComponent from "../utils/textGeometry";
import { useScroll } from "./ScrollControls";
import { TextGeometry } from "three/addons/geometries/TextGeometry.js";
import { LoadingContext } from "../context/loading.context";
import { Section3Context } from "../context/section3.context";

const MovingTextComponent = ({ isSeedInDestination }) => {
  const [generatedTextMesh, setGeneratedTextMesh] = useState(null);
  const [generatedPoints, setGeneratedPoints] = useState(null);
  const [fontTexture, setFontTexture] = useState(null);
  const [font, setFont] = useState(null);
  const [font2, setFont2] = useState(null);
  const [textOpacity, setTextOpacity] = useState(0);
  const [particlesCount, setParticlesCount] = useState();
  const { activeMode, showPlayButtons } = useContext(Section3Context);
  const { setLoading } = useContext(LoadingContext);
  const subTextMaterial = useRef(null);
  const bodyTextMaterial = useRef(null);
  const textOpacityInterval = useRef(null);
  const isTextRemoveInProgress = useRef(null);
  const isTextAddInProgress = useRef(null);
  const activeText = useRef(null);
  const { width } = useThree((state) => state.viewport);
  const { scene } = useThree();
  const { scroll } = useScroll();

  useEffect(() => {
    if (textOpacity == 0 || textOpacity == 1) {
      isTextRemoveInProgress.current = false;
    }
  }, [textOpacity]);
  useEffect(() => {
    if (
      !subTextMaterial.current &&
      !bodyTextMaterial.current &&
      !generatedTextMesh &&
      isTextRemoveInProgress.current
    ) {
      isTextRemoveInProgress.current = false;
    }
  }, [subTextMaterial.current, bodyTextMaterial.current, generatedTextMesh]);

  // section 2 details
  const section2TextDetails = useRef({
    isAdded: false,
    pointSize: 2.0,
    text: "DRIVEN BY GROWTH",
    align: "center",
    subText: "Specialized in distribution of high \nquality CBD products.",
    bodyText:
      "Since 2021 we have been trading flowers creating value \nfor partners and businesses by bridging the European \nCannabis Industry. We believe in strong relationships to \ndevelop a fair ecosystem based on trust and collaboration.",
  });

  // section 3 details
  const section3TextDetails = useRef({
    isAdded: false,
    text: "WE TAKE IT TO\nTHE FIELD",
    align: "center",
    pointSize: 1.3,
    subText:
      "Since 2018 we have explored Cannabis \nSativa and its versatility",
    bodyText:
      "What began as a humble cultivation operation, quickly \ntransformed into a thriving enterprise thanks to a collaborative \napproach. We partner with the best producers to assure top \nquality selection of new phenotypes. This is why today we \nare able to market unique products.",
  });

  // SECTION 3 OUTDOOR
  const section3OutdoorTextDetails = useRef({
    isAdded: false,
    text: "OUTDOOR",
    align: "center",
    pointSize: 1.3,
    subText:
      "Since 2018 we have explored Cannabis \nSativa and its versatility",
    bodyText:
      "What began as a humble cultivation operation, quickly \ntransformed into a thriving enterprise thanks to a collaborative \napproach. We partner with the best producers to assure top \nquality selection of new phenotypes. This is why today we \nare able to market unique products.",
  });

  // SECTION 3 INDOOR
  const section3IndoorTextDetails = useRef({
    isAdded: false,
    text: "INDOOR",
    align: "center",
    pointSize: 1.3,
    subText:
      "Since 2018 we have explored Cannabis \nSativa and its versatility",
    bodyText:
      "What began as a humble cultivation operation, quickly \ntransformed into a thriving enterprise thanks to a collaborative \napproach. We partner with the best producers to assure top \nquality selection of new phenotypes. This is why today we \nare able to market unique products.",
  });

  // SECTION 3 GREEN HOUSE
  const section3GreenHouseTextDetails = useRef({
    isAdded: false,
    text: "GREEN HOUSE",
    align: "center",
    pointSize: 1.3,
    subText:
      "Since 2018 we have explored Cannabis \nSativa and its versatility",
    bodyText:
      "What began as a humble cultivation operation, quickly \ntransformed into a thriving enterprise thanks to a collaborative \napproach. We partner with the best producers to assure top \nquality selection of new phenotypes. This is why today we \nare able to market unique products.",
  });
  // section 6 details
  const section6TextDetails = useRef({
    isAdded: false,
    text: "DRIVEN BY PASSION",
    align: "center",
    pointSize: 1.3,
    subText: "We only commit to excellence.",
    bodyText:
      "We curate the finest selections of CBD products, each product is meticulously  \nproduced and selected to deliver the ultimate experience in natural wellness. \nWe prioritize quality at every step of the process, from seed to harvest, \nour hemp flowers are organically grown with different setups and features. \nFrom classic favorites to rare cultivars, our collection offers something for everyone. \nRest assured knowing that all of our CBD hemp flowers undergo rigorous testing by \nthird-party laboratories. Transparency and integrity are at the core of everything we do.",
  });

  // section 7 details
  const section7TextDetails = useRef({
    isAdded: false,
    text: "WE ARE YOUR\nTRUSTED SUPPLIER",
    align: "center",
    pointSize: 1.3,
    subText: "Dalai offers complementary services to ease\nyour operations.",
    bodyText:
      "Since 2021 we have introduced quality control procedures,\nfilling and packing services and THC reduction treatments for all\nproducts we want to distribute. Dalai is consistently becoming the\nstandard for high quality products and assurance\non procedures and services.",
  });
  useEffect(() => {
    if (width < 5.67) {
      setParticlesCount(60);
      // section 2
      section2TextDetails.current.position = [-width * 0.21, -15.4, 2.6];
      section2TextDetails.current.subTextPosition = [
        -width * 0.21,
        -15.55,
        2.6,
      ];
      section2TextDetails.current.bodyTextPosition = [
        -width * 0.21,
        -15.8,
        2.6,
      ];
      section2TextDetails.current.textScale = [0.0028, -0.0028, 0.005];
      section2TextDetails.current.subTextSize = 9.9;
      section2TextDetails.current.bodyTextSize = 8;
      section2TextDetails.current.subText =
        "Specialized in distribution of high \nquality CBD products.";
      section2TextDetails.current.bodyText =
        "Since 2021 we have been trading flowers \ncreating value for partners and businesses \nby bridging the European Cannabis  \nIndustry. We believe in strong relationships \nto develop a fair ecosystem based \non trust and collaboration.";

      // section 3
      section3TextDetails.current.position = [-width * 0.067, 0.22, 4.2];
      // section3TextDetails.current.particlesCount = 30;
      section3TextDetails.current.subTextPosition = [-width * 0.049, 0.12, 4.4];
      section3TextDetails.current.bodyTextPosition = [
        -width * 0.049,
        0.06,
        4.4,
      ];
      section3TextDetails.current.textScale = [0.0012, -0.0012, 0.005];
      section3TextDetails.current.subTextSize = 3;
      section3TextDetails.current.bodyTextSize = 2.15;
      section3TextDetails.current.subText =
        "Since 2018 we have explored \nCannabis Sativa and its versatility";
      section3TextDetails.current.bodyText =
        "What began as a humble cultivation operation, \nquickly transformed into a thriving enterprise \nthanks to a collaborative approach. We partner \nwith the best producers to assure top quality \nselection of new phenotypes.This is why today \nwe are able to market unique products.";
      section3TextDetails.current.pointSize = 0.9;

      // section 3 outdoor
      section3OutdoorTextDetails.current.position = [-width * 0.067, 0.22, 4.2];
      section3OutdoorTextDetails.current.subTextPosition = [
        -width * 0.049,
        0.12,
        4.4,
      ];
      section3OutdoorTextDetails.current.bodyTextPosition = [
        -width * 0.049,
        0.06,
        4.4,
      ];
      section3OutdoorTextDetails.current.textScale = [0.0012, -0.0012, 0.005];
      section3OutdoorTextDetails.current.subTextSize = 3;
      section3OutdoorTextDetails.current.bodyTextSize = 2.15;
      section3OutdoorTextDetails.current.subText =
        "Since 2018 we have explored \nCannabis Sativa and its versatility";
      section3OutdoorTextDetails.current.bodyText =
        "What began as a humble cultivation operation, \nquickly transformed into a thriving enterprise \nthanks to a collaborative approach. We partner \nwith the best producers to assure top quality \nselection of new phenotypes.This is why today \nwe are able to market unique products.";
      section3OutdoorTextDetails.current.pointSize = 0.9;

      // section 3 indoor
      section3IndoorTextDetails.current.position = [-width * 0.067, 0.22, 4.2];
      section3IndoorTextDetails.current.subTextPosition = [
        -width * 0.049,
        0.12,
        4.4,
      ];
      section3IndoorTextDetails.current.bodyTextPosition = [
        -width * 0.049,
        0.06,
        4.4,
      ];
      section3IndoorTextDetails.current.textScale = [0.0012, -0.0012, 0.005];
      section3IndoorTextDetails.current.subTextSize = 3;
      section3IndoorTextDetails.current.bodyTextSize = 2.15;
      section3IndoorTextDetails.current.subText =
        "Since 2018 we have explored \nCannabis Sativa and its versatility";
      section3IndoorTextDetails.current.bodyText =
        "What began as a humble cultivation operation, \nquickly transformed into a thriving enterprise \nthanks to a collaborative approach. We partner \nwith the best producers to assure top quality \nselection of new phenotypes.This is why today \nwe are able to market unique products.";
      section3IndoorTextDetails.current.pointSize = 0.9;

      // section 3 green house
      section3GreenHouseTextDetails.current.position = [
        -width * 0.067,
        0.22,
        4.2,
      ];
      section3GreenHouseTextDetails.current.subTextPosition = [
        -width * 0.049,
        0.12,
        4.4,
      ];
      section3GreenHouseTextDetails.current.bodyTextPosition = [
        -width * 0.049,
        0.06,
        4.4,
      ];
      section3GreenHouseTextDetails.current.textScale = [
        0.0012, -0.0012, 0.005,
      ];
      section3GreenHouseTextDetails.current.subTextSize = 3;
      section3GreenHouseTextDetails.current.bodyTextSize = 2.15;
      section3GreenHouseTextDetails.current.subText =
        "Since 2018 we have explored \nCannabis Sativa and its versatility";
      section3GreenHouseTextDetails.current.bodyText =
        "What began as a humble cultivation operation, \nquickly transformed into a thriving enterprise \nthanks to a collaborative approach. We partner \nwith the best producers to assure top quality \nselection of new phenotypes.This is why today \nwe are able to market unique products.";
      section3GreenHouseTextDetails.current.pointSize = 0.9;

      // section 6
      section6TextDetails.current.position = [-width * 0.13, -19.8, 3.6];
      section6TextDetails.current.subTextPosition = [-width * 0.13, -19.9, 3.6];
      section6TextDetails.current.bodyTextPosition = [
        -width * 0.13,
        -20.02,
        3.6,
      ];
      section6TextDetails.current.textScale = [0.002, -0.002, 0.005];
      section6TextDetails.current.subTextSize = 5.2;
      section6TextDetails.current.bodyTextSize = 4;
      section6TextDetails.current.bodyText =
        "Since 2021 we have introduced quality control procedures,\nfilling and packing services and THC reduction treatments \nfor allproducts we want to distribute. Dalai is consistently \nbecoming the standard for high quality products and \nassurance on procedures and services.";

      // section 7
      section7TextDetails.current.position = [-width * 0.13, -21.9, 3.6];
      section7TextDetails.current.subTextPosition = [-width * 0.13, -22, 3.6];
      section7TextDetails.current.bodyTextPosition = [
        -width * 0.13,
        -22.15,
        3.6,
      ];
      section7TextDetails.current.textScale = [0.0017, -0.0017, 0.005];
      section7TextDetails.current.subTextSize = 5.1;
      section7TextDetails.current.bodyTextSize = 3.9;
      section7TextDetails.current.bodyText =
        "Since 2021 we have introduced quality control procedures,\nfilling and packing services and THC reduction treatments \nfor all products we want to distribute. Dalai is consistently \nbecoming the standard for high quality products and \nassurance on procedures and services.";
    } else if (width < 7.7 && width >= 5.67) {
      setParticlesCount(80);
      // section 2
      section2TextDetails.current.position = [-width * 0.21, -6.35, 2.6];
      section2TextDetails.current.subTextPosition = [-width * 0.21, -6.5, 2.6];
      section2TextDetails.current.bodyTextPosition = [
        -width * 0.21,
        -6.74,
        2.6,
      ];
      section2TextDetails.current.textScale = [0.003, -0.003, 0.01];
      section2TextDetails.current.subTextSize = 10.5;
      section2TextDetails.current.bodyTextSize = 7.5;
      section2TextDetails.current.subText =
        "Specialized in distribution of high \nquality CBD products.";
      section2TextDetails.current.bodyText =
        "Since 2021 we have been trading flowers \ncreating value for partners and businesses \nby bridging the European Cannabis  \nIndustry. We believe in strong relationships \nto develop a fair ecosystem based \non trust and collaboration.";

      // section 3
      section3TextDetails.current.position = [-width * 0.084, 0.1, 4];
      section3TextDetails.current.subTextPosition = [-width * 0.084, 0.017, 4];
      section3TextDetails.current.bodyTextPosition = [-width * 0.084, -0.1, 4];
      section3TextDetails.current.textScale = [0.0017, -0.0017, 0.01];
      section3TextDetails.current.subTextSize = 5.2;
      section3TextDetails.current.bodyTextSize = 3.8;
      section3TextDetails.current.subText =
        "Since 2018 we have explored \nCannabis Sativa and its versatility";
      section3TextDetails.current.bodyText =
        "What began as a humble cultivation operation, \nquickly transformed into a thriving enterprise \nthanks to a collaborative approach. We partner \nwith the best producers to assure top quality \nselection of new phenotypes.This is why today \nwe are able to market unique products.";

      // section 3 outdoor
      section3OutdoorTextDetails.current.position = [-width * 0.084, 0.1, 4];
      section3OutdoorTextDetails.current.subTextPosition = [
        -width * 0.084,
        0.017,
        4,
      ];
      section3OutdoorTextDetails.current.bodyTextPosition = [
        -width * 0.084,
        -0.1,
        4,
      ];
      section3OutdoorTextDetails.current.textScale = [0.0017, -0.0017, 0.01];
      section3OutdoorTextDetails.current.subTextSize = 5.2;
      section3OutdoorTextDetails.current.bodyTextSize = 3.8;
      section3OutdoorTextDetails.current.subText =
        "Since 2018 we have explored \nCannabis Sativa and its versatility";
      section3OutdoorTextDetails.current.bodyText =
        "What began as a humble cultivation operation, \nquickly transformed into a thriving enterprise \nthanks to a collaborative approach. We partner \nwith the best producers to assure top quality \nselection of new phenotypes.This is why today \nwe are able to market unique products.";

      // section 3 indoor
      section3IndoorTextDetails.current.position = [-width * 0.084, 0.1, 4];
      section3IndoorTextDetails.current.subTextPosition = [
        -width * 0.084,
        0.017,
        4,
      ];
      section3IndoorTextDetails.current.bodyTextPosition = [
        -width * 0.084,
        -0.1,
        4,
      ];
      section3IndoorTextDetails.current.textScale = [0.0017, -0.0017, 0.01];
      section3IndoorTextDetails.current.subTextSize = 5.2;
      section3IndoorTextDetails.current.bodyTextSize = 3.8;
      section3IndoorTextDetails.current.subText =
        "Since 2018 we have explored \nCannabis Sativa and its versatility";
      section3IndoorTextDetails.current.bodyText =
        "What began as a humble cultivation operation, \nquickly transformed into a thriving enterprise \nthanks to a collaborative approach. We partner \nwith the best producers to assure top quality \nselection of new phenotypes.This is why today \nwe are able to market unique products.";

      // section 3 greenhouse
      section3GreenHouseTextDetails.current.position = [-width * 0.084, 0.1, 4];
      section3GreenHouseTextDetails.current.subTextPosition = [
        -width * 0.084,
        0.017,
        4,
      ];
      section3GreenHouseTextDetails.current.bodyTextPosition = [
        -width * 0.084,
        -0.1,
        4,
      ];
      section3GreenHouseTextDetails.current.textScale = [0.0017, -0.0017, 0.01];
      section3GreenHouseTextDetails.current.subTextSize = 5.2;
      section3GreenHouseTextDetails.current.bodyTextSize = 3.8;
      section3GreenHouseTextDetails.current.subText =
        "Since 2018 we have explored \nCannabis Sativa and its versatility";
      section3GreenHouseTextDetails.current.bodyText =
        "What began as a humble cultivation operation, \nquickly transformed into a thriving enterprise \nthanks to a collaborative approach. We partner \nwith the best producers to assure top quality \nselection of new phenotypes.This is why today \nwe are able to market unique products.";

      // section 6
      section6TextDetails.current.position = [-width * 0.13, -19.8, 3.6];
      section6TextDetails.current.subTextPosition = [-width * 0.13, -19.9, 3.6];
      section6TextDetails.current.bodyTextPosition = [
        -width * 0.13,
        -20.03,
        3.6,
      ];
      section6TextDetails.current.textScale = [0.0025, -0.0025, 0.01];
      section6TextDetails.current.subTextSize = 5.8;
      section6TextDetails.current.bodyTextSize = 4.5;

      // section 7
      section7TextDetails.current.position = [-width * 0.13, -21.9, 3.6];
      section7TextDetails.current.subTextPosition = [-width * 0.13, -22, 3.6];
      section7TextDetails.current.bodyTextPosition = [
        -width * 0.13,
        -22.15,
        3.6,
      ];
      section7TextDetails.current.textScale = [0.0025, -0.0025, 0.01];
      section7TextDetails.current.subTextSize = 5.8;
      section7TextDetails.current.bodyTextSize = 4.5;
    } else if (width >= 7.7 && width < 11.34) {
      setParticlesCount(80);
      // section 2
      section2TextDetails.current.position = [-width * 0.21, 0.55, 2.6];
      section2TextDetails.current.subTextPosition = [-width * 0.21, 0.35, 2.6];
      section2TextDetails.current.bodyTextPosition = [-width * 0.21, 0.03, 2.6];
      section2TextDetails.current.textScale = [0.004, -0.004, 0.015];
      section2TextDetails.current.subTextSize = 12.5;
      section2TextDetails.current.bodyTextSize = 9.5;

      // section 3
      section3TextDetails.current.position = [-width * 0.13, 0.14, 3.5];
      section3TextDetails.current.subTextPosition = [-width * 0.13, 0.017, 3.5];
      section3TextDetails.current.bodyTextPosition = [-width * 0.0863, -0.1, 4];
      section3TextDetails.current.textScale = [0.003, -0.003, 0.015];
      section3TextDetails.current.subTextSize = 7;
      section3TextDetails.current.bodyTextSize = 4;
      section3TextDetails.current.bodyText =
        "What began as a humble cultivation operation, \nquickly transformed into a thriving enterprise \nthanks to a collaborative approach. We partner \nwith the best producers to assure top quality \nselection of new phenotypes.This is why today \nwe are able to market unique products.";

      // section 3 outdoor
      section3OutdoorTextDetails.current.position = [-width * 0.13, 0.14, 3.5];
      section3OutdoorTextDetails.current.subTextPosition = [
        -width * 0.13,
        0.017,
        3.5,
      ];
      section3OutdoorTextDetails.current.bodyTextPosition = [
        -width * 0.0863,
        -0.1,
        4,
      ];
      section3OutdoorTextDetails.current.textScale = [0.003, -0.003, 0.015];
      section3OutdoorTextDetails.current.subTextSize = 7;
      section3OutdoorTextDetails.current.bodyTextSize = 4;
      section3OutdoorTextDetails.current.bodyText =
        "What began as a humble cultivation operation, \nquickly transformed into a thriving enterprise \nthanks to a collaborative approach. We partner \nwith the best producers to assure top quality \nselection of new phenotypes.This is why today \nwe are able to market unique products.";

      // section 3 indoor
      section3IndoorTextDetails.current.position = [-width * 0.13, 0.14, 3.5];
      section3IndoorTextDetails.current.subTextPosition = [
        -width * 0.13,
        0.017,
        3.5,
      ];
      section3IndoorTextDetails.current.bodyTextPosition = [
        -width * 0.0863,
        -0.1,
        4,
      ];
      section3IndoorTextDetails.current.textScale = [0.003, -0.003, 0.015];
      section3IndoorTextDetails.current.subTextSize = 7;
      section3IndoorTextDetails.current.bodyTextSize = 4;
      section3IndoorTextDetails.current.bodyText =
        "What began as a humble cultivation operation, \nquickly transformed into a thriving enterprise \nthanks to a collaborative approach. We partner \nwith the best producers to assure top quality \nselection of new phenotypes.This is why today \nwe are able to market unique products.";

      // section 3 greenhouse
      section3GreenHouseTextDetails.current.position = [
        -width * 0.13,
        0.14,
        3.5,
      ];
      section3GreenHouseTextDetails.current.subTextPosition = [
        -width * 0.13,
        0.017,
        3.5,
      ];
      section3GreenHouseTextDetails.current.bodyTextPosition = [
        -width * 0.0863,
        -0.1,
        4,
      ];
      section3GreenHouseTextDetails.current.textScale = [0.003, -0.003, 0.015];
      section3GreenHouseTextDetails.current.subTextSize = 7;
      section3GreenHouseTextDetails.current.bodyTextSize = 4;
      section3GreenHouseTextDetails.current.bodyText =
        "What began as a humble cultivation operation, \nquickly transformed into a thriving enterprise \nthanks to a collaborative approach. We partner \nwith the best producers to assure top quality \nselection of new phenotypes.This is why today \nwe are able to market unique products.";

      // section 6
      section6TextDetails.current.position = [-width * 0.13, 0.2, 3.6];
      section6TextDetails.current.subTextPosition = [-width * 0.13, 0.08, 3.6];
      section6TextDetails.current.bodyTextPosition = [
        -width * 0.13,
        -0.02,
        3.6,
      ];
      section6TextDetails.current.textScale = [0.003, -0.003, 0.01];
      section6TextDetails.current.subTextSize = 6;
      section6TextDetails.current.bodyTextSize = 4.7;
      section6TextDetails.current.bodyText =
        "We curate the finest selections of CBD products, each product\n is meticulously  produced and selected to deliver the ultimate\nexperience in natural wellness. We prioritize quality at every step\nof the process, from seed to harvest, our hemp flowers are\norganically grown with different setups and features. From\nclassic favorites to rare cultivars, our collection offers something\nfor everyone. Rest assured knowing that all of our CBD hemp\nflowers undergo rigorous testing by third-party laboratories.\nTransparency and integrity are at the core of everything we do.";

      // section 7
      section7TextDetails.current.position = [-width * 0.13, 0.2, 3.6];
      section7TextDetails.current.subTextPosition = [-width * 0.13, 0.08, 3.6];
      section7TextDetails.current.bodyTextPosition = [
        -width * 0.13,
        -0.08,
        3.6,
      ];
      section7TextDetails.current.textScale = [0.0028, -0.0028, 0.01];
      section7TextDetails.current.subTextSize = 6;
      section7TextDetails.current.bodyTextSize = 4.7;
    } else if (width >= 11.34 && width <= 14.5) {
      setParticlesCount(80);
      // section 2
      section2TextDetails.current.position = [-width * 0.22, 0.2, 2.6];
      section2TextDetails.current.subTextPosition = [-width * 0.22, -0.1, 2.6];
      section2TextDetails.current.bodyTextPosition = [
        -width * 0.22,
        -0.48,
        2.6,
      ];
      section2TextDetails.current.textScale = [0.005, -0.005, 0.02];
      section2TextDetails.current.subTextSize = 15;
      section2TextDetails.current.bodyTextSize = 12;

      // section 3
      section3TextDetails.current.position = [-width * 0.13, 0.2, 3.5];
      section3TextDetails.current.subTextPosition = [-width * 0.13, 0.05, 3.5];
      section3TextDetails.current.bodyTextPosition = [-width * 0.0863, -0.1, 4];
      section3TextDetails.current.textScale = [0.0035, -0.0035, 0.02];
      section3TextDetails.current.subTextSize = 7.5;
      section3TextDetails.current.bodyTextSize = 4.2;

      // section 3 outdoor
      section3OutdoorTextDetails.current.position = [-width * 0.13, 0.2, 3.5];
      section3OutdoorTextDetails.current.subTextPosition = [
        -width * 0.13,
        0.05,
        3.5,
      ];
      section3OutdoorTextDetails.current.bodyTextPosition = [
        -width * 0.0863,
        -0.1,
        4,
      ];
      section3OutdoorTextDetails.current.textScale = [0.0035, -0.0035, 0.02];
      section3OutdoorTextDetails.current.subTextSize = 7.5;
      section3OutdoorTextDetails.current.bodyTextSize = 4.2;

      // section 3 indoor
      section3IndoorTextDetails.current.position = [-width * 0.13, 0.2, 3.5];
      section3IndoorTextDetails.current.subTextPosition = [
        -width * 0.13,
        0.05,
        3.5,
      ];
      section3IndoorTextDetails.current.bodyTextPosition = [
        -width * 0.0863,
        -0.1,
        4,
      ];
      section3IndoorTextDetails.current.textScale = [0.0035, -0.0035, 0.02];
      section3IndoorTextDetails.current.subTextSize = 7.5;
      section3IndoorTextDetails.current.bodyTextSize = 4.2;

      // section 3 green house
      section3GreenHouseTextDetails.current.position = [
        -width * 0.13,
        0.2,
        3.5,
      ];
      section3GreenHouseTextDetails.current.subTextPosition = [
        -width * 0.13,
        0.05,
        3.5,
      ];
      section3GreenHouseTextDetails.current.bodyTextPosition = [
        -width * 0.0863,
        -0.1,
        4,
      ];
      section3GreenHouseTextDetails.current.textScale = [0.0035, -0.0035, 0.02];
      section3GreenHouseTextDetails.current.subTextSize = 7.5;
      section3GreenHouseTextDetails.current.bodyTextSize = 4.2;

      // section 6
      section6TextDetails.current.position = [-width * 0.13, 0.2, 3.6];
      section6TextDetails.current.subTextPosition = [-width * 0.13, 0.05, 3.6];
      section6TextDetails.current.bodyTextPosition = [
        -width * 0.13,
        -0.05,
        3.6,
      ];
      section6TextDetails.current.textScale = [0.004, -0.004, 0.02];
      section6TextDetails.current.subTextSize = 8;
      section6TextDetails.current.bodyTextSize = 5.5;
      section6TextDetails.current.bodyText =
        "We curate the finest selections of CBD products, each product\n is meticulously  produced and selected to deliver the ultimate\nexperience in natural wellness. We prioritize quality at every\nstep of the process, from seed to harvest, our hemp flowers\nare organically grown with different setups and features. From\nclassic favorites to rare cultivars, our collection offers something\nfor everyone. Rest assured knowing that all of our CBD hemp\nflowers undergo rigorous testing by third-party laboratories.\nTransparency and integrity are at the core of everything we do.";

      // section 7
      section7TextDetails.current.position = [-width * 0.13, 0.2, 3.6];
      section7TextDetails.current.subTextPosition = [-width * 0.13, 0.05, 3.6];
      section7TextDetails.current.bodyTextPosition = [
        -width * 0.13,
        -0.15,
        3.6,
      ];
      section7TextDetails.current.textScale = [0.0035, -0.0035, 0.02];
      section7TextDetails.current.subTextSize = 8;
      section7TextDetails.current.bodyTextSize = 5.5;
    } else {
      setParticlesCount(80);
      // section 2
      section2TextDetails.current.position = [-width * 0.227, 0.3, 2.4];
      section2TextDetails.current.subTextPosition = [-width * 0.227, -0, 2.4];
      section2TextDetails.current.bodyTextPosition = [
        -width * 0.227,
        -0.4,
        2.4,
      ];
      section2TextDetails.current.textScale = [0.006, -0.006, 0.02];
      section2TextDetails.current.subTextSize = 15;
      section2TextDetails.current.bodyTextSize = 12;

      // section 3
      section3TextDetails.current.position = [-width * 0.13, 0.2, 3.5];
      section3TextDetails.current.subTextPosition = [-width * 0.13, 0.05, 3.5];
      section3TextDetails.current.bodyTextPosition = [-width * 0.0863, -0.1, 4];
      section3TextDetails.current.textScale = [0.004, -0.004, 0.02];
      section3TextDetails.current.subTextSize = 8;
      section3TextDetails.current.bodyTextSize = 4.5;

      // section 3 indoor
      section3IndoorTextDetails.current.position = [-width * 0.13, 0.2, 3.5];
      section3IndoorTextDetails.current.subTextPosition = [
        -width * 0.13,
        0.05,
        3.5,
      ];
      section3IndoorTextDetails.current.bodyTextPosition = [
        -width * 0.0863,
        -0.1,
        4,
      ];
      section3IndoorTextDetails.current.textScale = [0.004, -0.004, 0.02];
      section3IndoorTextDetails.current.subTextSize = 8;
      section3IndoorTextDetails.current.bodyTextSize = 4.5;

      // section 3 outdoor
      section3OutdoorTextDetails.current.position = [-width * 0.13, 0.2, 3.5];
      section3OutdoorTextDetails.current.subTextPosition = [
        -width * 0.13,
        0.05,
        3.5,
      ];
      section3OutdoorTextDetails.current.bodyTextPosition = [
        -width * 0.0863,
        -0.1,
        4,
      ];
      section3OutdoorTextDetails.current.textScale = [0.004, -0.004, 0.02];
      section3OutdoorTextDetails.current.subTextSize = 8;
      section3OutdoorTextDetails.current.bodyTextSize = 4.5;

      // section 3 greenhouse
      section3GreenHouseTextDetails.current.position = [
        -width * 0.13,
        0.2,
        3.5,
      ];
      section3GreenHouseTextDetails.current.subTextPosition = [
        -width * 0.13,
        0.05,
        3.5,
      ];
      section3GreenHouseTextDetails.current.bodyTextPosition = [
        -width * 0.0863,
        -0.1,
        4,
      ];
      section3GreenHouseTextDetails.current.textScale = [0.004, -0.004, 0.02];
      section3GreenHouseTextDetails.current.subTextSize = 8;
      section3GreenHouseTextDetails.current.bodyTextSize = 4.5;

      // section 6
      section6TextDetails.current.position = [-width * 0.13, 0.2, 3.6];
      section6TextDetails.current.subTextPosition = [-width * 0.13, 0.05, 3.6];
      section6TextDetails.current.bodyTextPosition = [
        -width * 0.13,
        -0.05,
        3.6,
      ];
      section6TextDetails.current.textScale = [0.004, -0.004, 0.02];
      section6TextDetails.current.subTextSize = 8;
      section6TextDetails.current.bodyTextSize = 5.5;

      // section 7
      section7TextDetails.current.position = [-width * 0.13, 0.2, 3.6];
      section7TextDetails.current.subTextPosition = [-width * 0.13, 0.05, 3.6];
      section7TextDetails.current.bodyTextPosition = [
        -width * 0.13,
        -0.15,
        3.6,
      ];
      section7TextDetails.current.textScale = [0.004, -0.004, 0.02];
      section7TextDetails.current.subTextSize = 8;
      section7TextDetails.current.bodyTextSize = 6;
    }
  }, [width]);

  // for text movement
  useFrame(({ clock }) => {
    if (generatedTextMesh) {
      generatedTextMesh.uniforms.time.value = clock.getElapsedTime();
    }
    if (generatedPoints) {
      generatedPoints.uniforms.time.value = clock.getElapsedTime();
    }
  });

  // to remove individual section text
  const removeSectionText = (sectionName) => {
    const keysToDispose = ["subTextuuid", "uuid", "bodyTextuuid"];
    keysToDispose.forEach(async (element) => {
      const object = await scene.getObjectByProperty(
        "uuid",
        sectionName.current[element]
      );
      if (object) {
        // object.geometry.dispose();
        // object.material.dispose();
        if (object.parent === scene.children[1]) {
          scene.children[1].remove(object); // Remove from specific container
        } else {
          scene.remove(object); // Remove from the scene if not in scene.children[1]
        }
      }
    });
    activeText.current = null;
  };

  useFrame(() => {
    const currentScroll = scroll.current * 100;
    // if (currentScroll < 23 || currentScroll > 96) {
    //   removeAllSectionText();
    // }

    // add section 2 text
    if (currentScroll > 58.5 && currentScroll < 66) {
      // if (section6TextDetails.current.isAdded) {
      //   const object = scene.getObjectByProperty(
      //     "uuid",
      //     section6TextDetails.current.uuid2
      //   );
      //   if (object) {
      //     scene.remove(object);
      //   }
      //   removeTextCheckFunction(section6TextDetails);
      // }
      if (
        section2TextDetails.current.isAdded &&
        isTextRemoveInProgress.current &&
        ((width >= 7.7 && currentScroll < 66) ||
          (width < 7.7 && currentScroll < 61.55))
      ) {
        increaseTextOpacity();
      }

      if (
        !section2TextDetails.current.isAdded &&
        currentScroll >= 61.5 &&
        ((width >= 7.7 && currentScroll < 66) ||
          (width < 7.7 && currentScroll < 61.55 && isSeedInDestination)) &&
        isTextShouldAdd()
      ) {
        const object = scene.getObjectByProperty(
          "uuid",
          section2TextDetails.current.uuid
        );
        // if (!object) {
        scene.add(section2TextDetails.current.textMesh);
        scene.add(section2TextDetails.current.subTextMesh);
        scene.add(section2TextDetails.current.bodyTextMesh);
        // }
        isTextAddInProgress.current = true;
        section2TextDetails.current.isAdded = true;
        subTextMaterial.current = section2TextDetails.current.subTextMaterial;
        setGeneratedTextMesh(section2TextDetails.current.material);
        bodyTextMaterial.current = section2TextDetails.current.bodyTextMaterial;
        increaseTextOpacity();
        scene.add(section2TextDetails.current.points);
        activeText.current = section2TextDetails.current;
      }
    }
    if (
      ((width >= 7.7 && (currentScroll >= 66 || currentScroll < 57)) ||
        (width < 7.7 && (currentScroll > 61.555 || currentScroll < 58))) &&
      section2TextDetails.current.isAdded &&
      !isTextRemoveInProgress.current
    ) {
      const object = scene.getObjectByProperty(
        "uuid",
        section2TextDetails.current.uuid2
      );
      if (object) {
        scene.remove(object);
      }
      removeTextCheckFunction(section2TextDetails);
    }

    // add section 3 text
    if (currentScroll > 76 && currentScroll < 96) {
      // if (section6TextDetails.current.isAdded) {
      //   const object = scene.getObjectByProperty(
      //     "uuid",
      //     section6TextDetails.current.uuid2
      //   );
      //   if (object) {
      //     scene.remove(object);
      //   }
      //   removeTextCheckFunction(section6TextDetails);
      // }
      if (
        !section3TextDetails.current.isAdded &&
        currentScroll > 74 &&
        currentScroll < 96 &&
        !showPlayButtons &&
        isTextShouldAdd() &&
        !isTextAddInProgress.current
      ) {
        const object = scene.getObjectByProperty(
          "uuid",
          section3TextDetails.current.uuid
        );
        // if (!object) {
        scene.add(section3TextDetails.current.textMesh);
        scene.add(section3TextDetails.current.subTextMesh);
        scene.add(section3TextDetails.current.bodyTextMesh);
        // }
        isTextAddInProgress.current = true;
        section3TextDetails.current.isAdded = true;
        subTextMaterial.current = section3TextDetails.current.subTextMaterial;
        setGeneratedTextMesh(section3TextDetails.current.material);
        bodyTextMaterial.current = section3TextDetails.current.bodyTextMaterial;
        increaseTextOpacity();
        scene.add(section3TextDetails.current.points);
        activeText.current = section3TextDetails.current;
      }
    }
    if (
      section3TextDetails.current.isAdded &&
      !isTextRemoveInProgress.current &&
      (showPlayButtons || currentScroll > 94 || currentScroll < 76)
    ) {
      if (section3TextDetails.current.isAdded) {
        const object = scene.getObjectByProperty(
          "uuid",
          section3TextDetails.current.uuid2
        );
        if (object) {
          scene.remove(object);
        }
        removeTextCheckFunction(section3TextDetails);
      }
    }

    // add section 3 outdoor text
    if (currentScroll > 76 && currentScroll < 96) {
      if (
        !section3OutdoorTextDetails.current.isAdded &&
        currentScroll > 74 &&
        currentScroll < 96 &&
        showPlayButtons &&
        isTextShouldAdd() &&
        activeMode === "outdoor" &&
        !isTextAddInProgress.current
      ) {
        const object = scene.getObjectByProperty(
          "uuid",
          section3OutdoorTextDetails.current.uuid
        );
        // if (!object) {
        scene.add(section3OutdoorTextDetails.current.textMesh);
        scene.add(section3OutdoorTextDetails.current.subTextMesh);
        scene.add(section3OutdoorTextDetails.current.bodyTextMesh);
        // }
        isTextAddInProgress.current = true;
        section3OutdoorTextDetails.current.isAdded = true;
        subTextMaterial.current =
          section3OutdoorTextDetails.current.subTextMaterial;
        setGeneratedTextMesh(section3OutdoorTextDetails.current.material);
        bodyTextMaterial.current =
          section3OutdoorTextDetails.current.bodyTextMaterial;
        increaseTextOpacity();
        scene.add(section3OutdoorTextDetails.current.points);
        activeText.current = section3OutdoorTextDetails.current;
      }
    }

    if (
      ((showPlayButtons && (currentScroll > 94 || currentScroll < 76)) ||
        activeMode !== "outdoor" ||
        !showPlayButtons) &&
      section3OutdoorTextDetails.current.isAdded &&
      !isTextRemoveInProgress.current
    ) {
      if (section3OutdoorTextDetails.current.isAdded) {
        const object = scene.getObjectByProperty(
          "uuid",
          section3OutdoorTextDetails.current.uuid2
        );
        if (object) {
          scene.remove(object);
        }
        removeTextCheckFunction(section3OutdoorTextDetails);
      }
    }

    // add section 3 green text
    if (currentScroll > 76 && currentScroll < 96) {
      if (
        !section3GreenHouseTextDetails.current.isAdded &&
        currentScroll > 74 &&
        currentScroll < 96 &&
        showPlayButtons &&
        isTextShouldAdd() &&
        activeMode === "green" &&
        !isTextAddInProgress.current
      ) {
        const object = scene.getObjectByProperty(
          "uuid",
          section3GreenHouseTextDetails.current.uuid
        );
        // if (!object) {
        scene.add(section3GreenHouseTextDetails.current.textMesh);
        scene.add(section3GreenHouseTextDetails.current.subTextMesh);
        scene.add(section3GreenHouseTextDetails.current.bodyTextMesh);
        // }
        isTextAddInProgress.current = true;
        section3GreenHouseTextDetails.current.isAdded = true;
        subTextMaterial.current =
          section3GreenHouseTextDetails.current.subTextMaterial;
        setGeneratedTextMesh(section3GreenHouseTextDetails.current.material);
        bodyTextMaterial.current =
          section3GreenHouseTextDetails.current.bodyTextMaterial;
        increaseTextOpacity();
        scene.add(section3GreenHouseTextDetails.current.points);
        activeText.current = section3GreenHouseTextDetails.current;
      }
    }

    if (
      ((showPlayButtons && (currentScroll > 94 || currentScroll < 76)) ||
        activeMode !== "green" ||
        !showPlayButtons) &&
      section3GreenHouseTextDetails.current.isAdded &&
      !isTextRemoveInProgress.current
    ) {
      if (section3GreenHouseTextDetails.current.isAdded) {
        const object = scene.getObjectByProperty(
          "uuid",
          section3GreenHouseTextDetails.current.uuid2
        );
        if (object) {
          scene.remove(object);
        }
        removeTextCheckFunction(section3GreenHouseTextDetails);
      }
    }

    // add section 3 indoor text
    if (currentScroll > 76 && currentScroll < 96) {
      if (
        !section3IndoorTextDetails.current.isAdded &&
        currentScroll > 74 &&
        currentScroll < 96 &&
        showPlayButtons &&
        isTextShouldAdd() &&
        activeMode === "indoor" &&
        !isTextAddInProgress.current
      ) {
        const object = scene.getObjectByProperty(
          "uuid",
          section3IndoorTextDetails.current.uuid
        );
        // if (!object) {
        scene.add(section3IndoorTextDetails.current.textMesh);
        scene.add(section3IndoorTextDetails.current.subTextMesh);
        scene.add(section3IndoorTextDetails.current.bodyTextMesh);
        // }
        isTextAddInProgress.current = true;
        section3IndoorTextDetails.current.isAdded = true;
        subTextMaterial.current =
          section3IndoorTextDetails.current.subTextMaterial;
        setGeneratedTextMesh(section3IndoorTextDetails.current.material);
        bodyTextMaterial.current =
          section3IndoorTextDetails.current.bodyTextMaterial;
        increaseTextOpacity();
        scene.add(section3IndoorTextDetails.current.points);
        activeText.current = section3IndoorTextDetails.current;
      }
    }

    if (
      ((showPlayButtons && (currentScroll > 94 || currentScroll < 76)) ||
        activeMode !== "indoor" ||
        !showPlayButtons) &&
      section3IndoorTextDetails.current.isAdded &&
      !isTextRemoveInProgress.current
    ) {
      if (section3IndoorTextDetails.current.isAdded) {
        const object = scene.getObjectByProperty(
          "uuid",
          section3IndoorTextDetails.current.uuid2
        );
        if (object) {
          scene.remove(object);
        }
        removeTextCheckFunction(section3IndoorTextDetails);
      }
    }

    // add section 6 text
    if (currentScroll > 6.8 && currentScroll < 14.2 && width >= 7.7) {
      if (
        section6TextDetails.current.isAdded &&
        !isTextAddInProgress.current &&
        isTextRemoveInProgress.current
      ) {
        increaseTextOpacity();
        scene.add(section6TextDetails.current.points);
      }
      if (
        textOpacity == 0 &&
        section6TextDetails.current.isAdded &&
        !isTextAddInProgress.current &&
        !isTextRemoveInProgress.current
      ) {
        increaseTextOpacity();
      }
      if (
        !section6TextDetails.current.isAdded &&
        currentScroll > 6.8 &&
        isTextShouldAdd() &&
        !isTextAddInProgress.current
      ) {
        const object = scene.getObjectByProperty(
          "uuid",
          section6TextDetails.current.uuid
        );
        // if (!object) {
        scene.add(section6TextDetails.current.textMesh);
        scene.add(section6TextDetails.current.subTextMesh);
        scene.add(section6TextDetails.current.bodyTextMesh);
        // }
        isTextRemoveInProgress.current = false;
        isTextAddInProgress.current = true;
        section6TextDetails.current.isAdded = true;
        subTextMaterial.current = section6TextDetails.current.subTextMaterial;
        setGeneratedTextMesh(section6TextDetails.current.material);
        bodyTextMaterial.current = section6TextDetails.current.bodyTextMaterial;
        increaseTextOpacity();
        scene.add(section6TextDetails.current.points);
        activeText.current = section6TextDetails.current;
      }
    }

    if (
      (currentScroll > 14.2 || currentScroll < 5.5) &&
      section6TextDetails.current.isAdded &&
      !isTextRemoveInProgress.current
    ) {
      const object = scene.getObjectByProperty(
        "uuid",
        section6TextDetails.current.uuid2
      );
      if (object) {
        scene.remove(object);
      }
      removeTextCheckFunction(section6TextDetails);
    }

    // add section 7 text
    if (currentScroll > 14.3 && currentScroll < 18.8 && width >= 7.7) {
      if (
        textOpacity == 0 &&
        section7TextDetails.current.isAdded &&
        !isTextAddInProgress.current &&
        !isTextRemoveInProgress.current
      ) {
        increaseTextOpacity();
      }
      if (
        section7TextDetails.current.isAdded &&
        !isTextAddInProgress.current &&
        isTextRemoveInProgress.current
      ) {
        scene.add(section6TextDetails.current.points);
        increaseTextOpacity();
      }
      if (
        textOpacity == 0 &&
        section7TextDetails.current.isAdded &&
        !isTextAddInProgress.current &&
        !isTextRemoveInProgress.current
      ) {
        increaseTextOpacity();
      }
      if (
        !section7TextDetails.current.isAdded &&
        currentScroll > 14.2 &&
        isTextShouldAdd() &&
        !isTextAddInProgress.current
      ) {
        const object = scene.getObjectByProperty(
          "uuid",
          section7TextDetails.current.uuid
        );
        // if (!object) {
        isTextRemoveInProgress.current = false;

        scene.add(section7TextDetails.current.textMesh);
        scene.add(section7TextDetails.current.subTextMesh);
        scene.add(section7TextDetails.current.bodyTextMesh);
        // }
        isTextAddInProgress.current = true;
        section7TextDetails.current.isAdded = true;
        subTextMaterial.current = section7TextDetails.current.subTextMaterial;
        setGeneratedTextMesh(section7TextDetails.current.material);
        bodyTextMaterial.current = section7TextDetails.current.bodyTextMaterial;
        increaseTextOpacity();
        scene.add(section7TextDetails.current.points);
        activeText.current = section7TextDetails.current;
      }
    }

    if (currentScroll > 18.8 || currentScroll < 14.2) {
      if (
        section7TextDetails.current.isAdded &&
        !isTextRemoveInProgress.current
      ) {
        const object = scene.getObjectByProperty(
          "uuid",
          section7TextDetails.current.uuid2
        );
        if (object) {
          scene.remove(object);
        }
        removeTextCheckFunction(section7TextDetails);
      }
    }
  });

  // for loading fontTexture
  useEffect(() => {
    const loadFontTexture = async () => {
      try {
        const texture = await new THREE.TextureLoader().loadAsync(
          "/font/manifold.png"
        );
        setLoading("font", true);

        setFontTexture(texture);
        const loader = new FontLoader();

        loader.load("/font/manifold.json", function (font) {
          setFont(font);
          setLoading("section5json", true);
        });

        loader.load(
          "https://threejs.org/examples/fonts/helvetiker_regular.typeface.json",
          function (font) {
            setFont2(font);
            setLoading("section5font", true);
          }
        );
      } catch (error) {
        console.error("Error loading font texture:", error);
      }
    };
    loadFontTexture();
  }, []);

  useEffect(() => {
    if (font && font2 && fontTexture) {
      addText(section2TextDetails);
      addText(section3TextDetails);
      addText(section3IndoorTextDetails);
      addText(section3OutdoorTextDetails);
      addText(section3GreenHouseTextDetails);
      addText(section6TextDetails);
      addText(section7TextDetails);
    }
  }, [fontTexture, font, font2]);

  // mouse events
  const setupMouseEvents = (materialText, material) => {
    const handleMouseMove = (event) => {
      const mouse = {
        x: event.clientX / window.innerWidth,
        y: event.clientY / window.innerHeight,
      };
      materialText.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
      material.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
    };
    const handleTouchMove = (event) => {
      const mouse = {
        x: event.touches[0].clientX / window.innerWidth,
        y: event.touches[0].clientY / window.innerHeight,
      };
      materialText.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
      material.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
    };
    const canvas = document.querySelector("canvas");
    const handleWindowResize = (event) => {
      materialText.uniforms.viewport.value = new THREE.Vector2(
        canvas.width,
        canvas.height
      );
      material.uniforms.viewport.value = new THREE.Vector2(
        canvas.width,
        canvas.height
      );
    };
    const handleTouchEnd = () => {
      materialText.uniforms.uMouse.value = new THREE.Vector2(0, 0);
      material.uniforms.uMouse.value = new THREE.Vector2(0, 0);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);
  };

  useFrame(() => {
    if (
      subTextMaterial.current &&
      bodyTextMaterial.current &&
      generatedTextMesh
    ) {
      if (textOpacity < 1) {
        subTextMaterial.current.transparent = true;
        subTextMaterial.current.depthWrite = false;
        bodyTextMaterial.current.transparent = true;
        bodyTextMaterial.current.depthWrite = false;
      } else {
        subTextMaterial.current.transparent = false;
        subTextMaterial.current.depthWrite = true;
        bodyTextMaterial.current.transparent = false;
        bodyTextMaterial.current.depthWrite = true;
      }
      subTextMaterial.current.opacity = textOpacity;
      subTextMaterial.current.needsUpdate = true;
      bodyTextMaterial.current.opacity = textOpacity;
      bodyTextMaterial.current.needsUpdate = true;
      generatedTextMesh.uniforms.opacity.value = textOpacity;
      if (
        textOpacity == 0 &&
        (subTextMaterial.current.opacity == 0 ||
          generatedTextMesh.uniforms.opacity.value == 0 ||
          subTextMaterial.current.opacity == 0) &&
        !isTextAddInProgress.current
      ) {
        setGeneratedTextMesh(null);
        subTextMaterial.current = null;
        bodyTextMaterial.current = null;
        isTextRemoveInProgress.current = false;
        activeText.current.isAdded = false;
        removeSectionText(activeText);
        // removeSectionText(activeText);
      }
    }
  });

  // addText
  const addText = (textDetails) => {
    isTextRemoveInProgress.current = false;
    const geometry = TextGeometryComponent({
      text: textDetails.current.text,
      font: font,
      align: textDetails.current.align,
      flipY: fontTexture.flipY,
      scene,
    });

    const material = new THREE.RawShaderMaterial(
      MSDFShader({
        map: fontTexture,
        transparent: true,
        color: 0xffffff,
        side: THREE.DoubleSide,
        glslVersion: THREE.GLSL3,
        depthTest: true,
        depthWrite: true,
        opacity: 0,
      })
    );

    let layout = geometry.geometry.layout;
    let textMesh = new THREE.Mesh(geometry.bufferGeometry, material);

    textDetails.current.material = material;
    textMesh.scale.set(...textDetails.current.textScale);
    textMesh.position.set(...textDetails.current.position);

    textDetails.current.uuid = textMesh.uuid;

    const textGeometry = new TextGeometry(textDetails.current.subText, {
      font: font2,
      size: textDetails.current.subTextSize,
      height: 4,
    });

    const textMaterial = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: 0,
    });

    const textMesh1 = new THREE.Mesh(textGeometry, textMaterial);
    textMesh1.scale.set(0.006, 0.006, 0);
    textMesh1.position.set(...textDetails.current.subTextPosition);
    // subTextMaterial.current = textMaterial;
    textDetails.current.subTextMaterial = textMaterial;
    textDetails.current.subTextuuid = textMesh1.uuid;
    textDetails.current.subTextMesh = textMesh1;

    const textGeometry2 = new TextGeometry(textDetails.current.bodyText, {
      font: font2,
      size: textDetails.current.bodyTextSize,
      height: 4,
    });

    const textMaterial2 = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: 0,
    });
    const textMesh2 = new THREE.Mesh(textGeometry2, textMaterial2);
    textMesh2.scale.set(0.006, 0.006, 0);
    textMesh2.position.set(...textDetails.current.bodyTextPosition);

    // if (
    //   width < 7.7 &&
    //   (section2TextDetails.current.isAdded ||
    //     section6TextDetails.current.isAdded ||
    //     section7TextDetails.current.isAdded)
    // ) {
    //   scene.children[1].add(textMesh);
    //   scene.children[1].add(textMesh1);
    //   scene.children[1].add(textMesh2);
    // } else {
    //   scene.add(textMesh);
    //   scene.add(textMesh1);
    //   scene.add(textMesh2);
    // }

    // increaseTextOpacity();
    // bodyTextMaterial.current = textMaterial2;
    textDetails.current.bodyTextMaterial = textMaterial2;
    textDetails.current.bodyTextuuid = textMesh2.uuid;
    textDetails.current.textMesh = textMesh;
    textDetails.current.bodyTextMesh = textMesh2;

    // setGeneratedTextMesh(textMesh);
    setupMouseEvents(textMesh.material, material);
    addObjects(layout, textMesh, textDetails);
  };

  // addObjects
  const addObjects = (layout, textMesh, textDetails) => {
    const textBoundingBox = new THREE.Box3().setFromObject(textMesh);

    let number = textDetails?.current.particlesCount
      ? textDetails?.current.particlesCount
      : particlesCount;
    let geo = new THREE.BufferGeometry();
    let pos = [];

    for (let i = 0; i < number; i++) {
      // Generate random positions within the bounding box of the text mesh
      let x = THREE.MathUtils.randFloat(
        textBoundingBox.min.x,
        textBoundingBox.max.x
      );
      let y = THREE.MathUtils.randFloat(
        textBoundingBox.min.y,
        textBoundingBox.max.y
      );
      let z = THREE.MathUtils.randFloat(
        textBoundingBox.min.z,
        textBoundingBox.max.z
      );

      pos.push(x, y, z);
    }

    pos = new Float32Array(pos);
    geo.setAttribute("position", new THREE.BufferAttribute(pos, 3));

    const material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable",
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        viewport: {
          value: new THREE.Vector2(window.innerWidth, window.innerHeight),
        },
        pointSize: { value: textDetails.current.pointSize },
        uMouse: { value: new THREE.Vector2(0, 0) },
        resolution: { value: new THREE.Vector4() },
      },
      depthTest: true,
      depthWrite: true,
      transparent: true,
      vertexShader: vertex,
      fragmentShader: fragment,
    });

    const points = new THREE.Points(geo, material);
    // setGeneratedPoints(points);
    textDetails.current.points = points;
    // scene.add(points);
    textDetails.current.uuid2 = points.uuid;
    addTimer(points);
    setupMouseEvents(textMesh.material, material);
  };

  // movement of particles
  const addTimer = (mesh) => {
    const boundingBox = new THREE.Box3().setFromObject(mesh);
    const areaBounds = {
      minX: boundingBox.min.x,
      maxX: boundingBox.max.x,
      minY: boundingBox.min.y,
      maxY: boundingBox.max.y,
      minZ: boundingBox.min.z,
      maxZ: boundingBox.max.z,
    };
    const maxVelocity = 0.05; // Maximum speed for particles
    const particlesCount = mesh.geometry.attributes.position.count;
    const velocities = new Array(particlesCount * 3)
      .fill(0)
      .map(() => (Math.random() - 0.5) * maxVelocity);

    setInterval(() => {
      const positions = mesh.geometry.attributes.position.array;

      for (let i = 0; i < positions.length; i++) {
        positions[i] += velocities[i];

        // Check and adjust position to stay within bounds
        if (i % 3 === 0) {
          // X-axis
          if (
            positions[i] < areaBounds.minX ||
            positions[i] > areaBounds.maxX
          ) {
            velocities[i] = -velocities[i]; // Reverse direction upon reaching boundary
          }
          positions[i] = Math.max(
            areaBounds.minX,
            Math.min(areaBounds.maxX, positions[i])
          );
        } else if (i % 3 === 1) {
          // Y-axis
          if (
            positions[i] < areaBounds.minY ||
            positions[i] > areaBounds.maxY
          ) {
            velocities[i] = -velocities[i]; // Reverse direction upon reaching boundary
          }
          positions[i] = Math.max(
            areaBounds.minY,
            Math.min(areaBounds.maxY, positions[i])
          );
        } else {
          // Z-axis
          if (
            positions[i] < areaBounds.minZ ||
            positions[i] > areaBounds.maxZ
          ) {
            velocities[i] = -velocities[i]; // Reverse direction upon reaching boundary
          }
          positions[i] = Math.max(
            areaBounds.minZ,
            Math.min(areaBounds.maxZ, positions[i])
          );
        }
      }
      // Update the buffer geometry to reflect changes
      mesh.geometry.attributes.position.needsUpdate = true;
    }, 100);
  };

  const isTextShouldAdd = () => {
    return (
      !generatedTextMesh &&
      !subTextMaterial.current &&
      !bodyTextMaterial.current
    );
  };

  const increaseTextOpacity = () => {
    clearInterval(textOpacityInterval.current);
    textOpacityInterval.current = null;
    isTextRemoveInProgress.current = false;
    const fadeIn = () => {
      setTextOpacity((prevOpacity) => {
        const newValue = Math.min(prevOpacity + 0.05, 1);
        if (newValue > 0.98) {
          clearInterval(textOpacityInterval.current);
          textOpacityInterval.current = null;
          isTextRemoveInProgress.current = false;
          isTextAddInProgress.current = false;
        }
        return newValue;
      });
    };

    textOpacityInterval.current = setInterval(() => {
      fadeIn();
    }, 100); // Change the interval as per your requirement
  };

  const removeTextCheckFunction = (textDetails) => {
    // if (isTextShouldRemove()) removeSectionText(textDetails);
    if (!isTextRemoveInProgress.current) {
      isTextRemoveInProgress.current = true;
      decreaseTextOpacity(textDetails);
    }
  };

  const decreaseTextOpacity = (textDetails) => {
    isTextRemoveInProgress.current = true;
    isTextAddInProgress.current = false;
    clearInterval(textOpacityInterval.current);
    textOpacityInterval.current = null;
    const fadeOut = () => {
      setTextOpacity((prevOpacity) => {
        const newValue = Math.max(prevOpacity - 0.15, 0);
        if (newValue == 0) {
          textDetails.current.isAdded = false;
          isTextRemoveInProgress.current = false;
          clearInterval(textOpacityInterval.current);
          textOpacityInterval.current = null;
        }
        return newValue;
      });
    };

    textOpacityInterval.current = setInterval(() => {
      fadeOut();
    }, 50); // Change the interval as per your requirement
  };

  return <></>;
};
export default MovingTextComponent;
