import { useLoader, useFrame } from "@react-three/fiber";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { LoadingContext } from "../../context/loading.context";
import { useScroll } from "../ScrollControls.js";
import * as THREE from "three";
import { setScrollTopToPercentage } from "../../utils/setScrollPercentage";
const PlantModel = ({ modelPath, scale, animate }) => {
  const [rotateLeft, setRotateLeft] = useState(true);
  const rotationSpeed = 0.0003;
  const rotationInterval = 8000;
  const currentRotation = useRef(0);
  const { setLoading } = useContext(LoadingContext);
  const gltf = useLoader(GLTFLoader, modelPath);
  gltf.scene.scale.set(...scale);
  gltf.scene.renderOrder = 1;
  const modelRef = useRef();
  const { scroll, el } = useScroll();
  const prevScrollRef = useRef(null);
  const [reachedSection1, setReachedSection1] = useState(false);

  useEffect(() => {
    if (gltf.scene) {
      setLoading("section1", true);
    }
  }, [gltf, setLoading]);

  useEffect(() => {
    setScrollTopToPercentage(36.79, el);
    scroll.current = 35 / 100;

    // setInterval(() => {
    //   setLoading("section1Reached", true);
    // }, 4000);

    const rotationTimer = setInterval(() => {
      setRotateLeft((prevRotateLeft) => !prevRotateLeft);
    }, rotationInterval);

    return () => {
      clearInterval(rotationTimer);
    };
  }, []);

  useEffect(() => {
    if (reachedSection1) {
      setLoading("section1Reached", true);
    }
  }, [reachedSection1]);

  useFrame(() => {
    if (modelRef.current) {
      const currentScroll = scroll.current * 100;

      // auto-rotate
      if (currentScroll <= 37 && currentScroll > 30) {
        if (rotateLeft) {
          currentRotation.current += rotationSpeed;
        } else {
          currentRotation.current -= rotationSpeed;
        }
      }

      // rotate on scroll
      modelRef.current.rotation.y = currentRotation.current;
      if (currentScroll <= 37 && currentScroll > 30) {
        if (scroll.current > prevScrollRef.current) {
          currentRotation.current += 0.004;
        } else if (scroll.current < prevScrollRef.current) {
          currentRotation.current -= 0.004;
        }
      }

      if (currentScroll >= 34.9 && currentScroll <= 35) {
        setReachedSection1(true);
      }

      // section 1 exit
      if (currentScroll >= 35 && currentScroll < 42.5) {
        scroll.current -= 0.14 / 100;
      }
      if (currentScroll > 35.08 && currentScroll < 35.3) {
        setScrollTopToPercentage(35, el);
      }

      // section 1 entry
      if (currentScroll >= 26 && currentScroll < 34.7) {
        scroll.current += 0.14 / 100;
        if (currentScroll > 34.3 && currentScroll < 34.7) {
          setScrollTopToPercentage(35, el);
        }
      }
    }

    prevScrollRef.current = scroll.current;
  });

  return (
    <primitive
      ref={modelRef}
      object={animate ? gltf.scene : gltf.scene.clone(true)}
    />
  );
};

export default PlantModel;
